import React, { useEffect, useState } from "react";
import { Form, Input, Button, Upload, Row, Col, message, Select } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CustomSelect from "../../shared/customSelect/indes";
import { dropDownPayload } from "../../../utils/helper";
import { getAllPackageApi } from "../../../services/packages";

function AddTenant({ form, onFinish, id }) {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(false);
  const getLanguage = async () => {
    try {
      const { data: response } = await getAllPackageApi();
      const { data: record } = response;
      setPackages(record.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getLanguage();
  }, []);

  const uploadButton = (
    <button style={{ border: 0, background: "none" }} type="button">
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </button>
  );

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={<span className="text-lightGrey font-medium">Name</span>}
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter name"
              />
            </Form.Item>

            <Form.Item
              name="url"
              label={<span className="text-lightGrey font-medium">Url</span>}
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                disabled={id?.length ? true : false}
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter url"
              />
            </Form.Item>

            <Form.Item
              name="email"
              label={<span className="text-lightGrey font-medium">email</span>}
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="email"
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter email"
              />
            </Form.Item>

            <Form.Item
              name="contactNo"
              label={
                <span className="text-lightGrey font-medium">
                  Contact Number
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter contact Number"
              />
            </Form.Item>

            <Form.Item
              name="subscribedAt"
              label={
                <span className="text-lightGrey font-medium">
                  Subscribed at
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="date"
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter subscribed at"
              />
            </Form.Item>

            <Form.Item
              name="expiredAt"
              label={
                <span className="text-lightGrey font-medium">Expired at</span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="date"
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter expired at"
              />
            </Form.Item>

            <Form.Item
              name="schemaPrefix"
              label={
                <span className="text-lightGrey font-medium">
                  Schema Prefix
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                disabled={id?.length ? true : false}
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter schema prefix"
              />
            </Form.Item>
            <Form.Item
              name="packageId"
              label={
                <span className="text-lightGrey font-medium">Package</span>
              }
            >
              <CustomSelect
                options={dropDownPayload(packages)}
                placeholder={"Select packages"}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <div className="flex items-center justify-center gap-3 mt-7">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Save
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddTenant;
