import React, { useEffect, useRef } from "react";

export default function CustomModal({
  isModalOpen,
  closeModal,
  heading,
  children,
  className = "",
}) {
  const modalRef = useRef(null);

  useEffect(() => {
    function handleOutsideClick(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }

    if (isModalOpen) {
      // document.addEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isModalOpen, closeModal]);

  return (
    <div>
      {isModalOpen && (
        <div className="overlay fixed top-0 bottom-0 left-0 w-full h-full bg-black opacity-35 z-10" />
      )}

      <div
        id="addSeasonPopup"
        ref={modalRef}
        className={`fixed bg-white rounded-l-3xl px-6 pb-6 transition-all duration-150 ease-in-out z-20 top-2/4 
          -translate-y-2/4 min-w-120 max-w-[80%] max-h-[80%] overflow-auto scrollbar ${className} ${
            isModalOpen ? "right-0" : "-right-[120rem]"
          }`}
      >
        {heading && (
          <div className="sticky top-0 bg-white z-10 flex items-center justify-start border-b pt-6 pb-4 mb-5">
            <svg
              onClick={closeModal}
              className="addSeasonPopupClose mr-10 cursor-pointer"
              width={8}
              height={14}
              viewBox="0 0 9 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8 1L1 7.99646L8 14.9929"
                stroke="#FE5E5E"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <h3 className="font-medium text-2xl">{heading}</h3>
          </div>
        )}
        {children}
      </div>
    </div>
  );
}
