import React, { useEffect, useState } from "react";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import { Form } from "antd";
import EditStore from "../../component/outlet/editStore";
import { getStoreApi, updateStore } from "../../services/outlet";
import {
  getAllLanguages,
  getAllListOfValueByKey,
} from "../../services/globalService";

function Store() {
  const [id, setId] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [data, setData] = useState([]);
  const [language, setLanguage] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [form] = Form.useForm();

  const getCurrency = async () => {
    try {
      const { data: response } = await getAllListOfValueByKey("currency");
      const { data: record } = response;
      setCurrency(record.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getLanguage = async () => {
    try {
      const { data: response } = await getAllLanguages();
      const { data: record } = response;
      setLanguage(record.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getStore = async () => {
    try {
      const { data: response } = await getStoreApi();
      const { data: record } = response;
      setData(record);
      setUploadedUrl(record.logo);
      form.setFieldsValue(record);
    } catch {
      return ServerError();
    }
  };

  useEffect(() => {
    getLanguage();
    getCurrency();
    getStore();
  }, []);

  const onFinish = async (values) => {
    try {
      const response = await updateStore(values, data._id);
      if (response.data.meta.success) {
        openNotification("success", "Store successfully saved");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  return (
    <main className="mt-6 flex-1 flex flex-col overflow-hidden">
      <div className="p-6 bg-white rounded-3xl h-full flex flex-col">
        <div className="flex items-center justify-between mb-8 text-left">
          <h2 className="text-2xl font-medium flex-1">Store</h2>
        </div>
        <div className="flex flex-col justify-between overflow-auto scrollbar">
          <EditStore
            form={form}
            onFinish={onFinish}
            language={language}
            currency={currency}
            uploadedUrl={uploadedUrl}
            setUploadedUrl={setUploadedUrl}
          />
        </div>
      </div>
    </main>
  );
}

export default Store;
