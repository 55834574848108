import React, { useEffect } from "react";
import {
  ResetLoading,
  getLoadStatus,
  getTotalRequest,
} from "../../../redux/feature/loader";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";

const AppLoader = ({ status, type, showInfo = false }) => {
  const dispatch = useDispatch();
  const loading = useSelector(getLoadStatus);
  const totalRequest = useSelector(getTotalRequest);

  const getPrecentage = (loadingStatus, totalLoader) => {
    const percentage = (loadingStatus / totalLoader) * 100;
    if (percentage < 90) {
      return "";
    } else {
      return percentage;
    }
  };
  useEffect(() => {
    if (loading == totalRequest) {
      dispatch(ResetLoading());
    }
  }, [loading, totalRequest]);
  return (
    <div className={"custom-api-progress"}>
      {loading < totalRequest && (
        <>
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)", // Add background overlay
              zIndex: 9999,
            }}
          >
            <Spin size="large" />
          </div>
          {getPrecentage(loading, totalRequest)}
        </>
      )}
    </div>
  );
};

export default AppLoader;
