import { Form, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getAllVariantProduct } from "../../services/product";
import { ServerError } from "../shared/notification";

import { dropDownPayload } from "../../utils/helper";
import PurchasePricing from "./purchasePricing";
import { getAllListOfValueByKey } from "../../services/globalService";

function ComponentProduct({ form }) {
  const { Option } = Select;
  const [products, setProducts] = useState([]);
  const [discountType, setDiscountType] = useState([]);

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("discount_type");
      setDiscountType(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
  }, []);

  const getVariantProducts = async () => {
    try {
      const {
        data: { data },
      } = await getAllVariantProduct();
      const pros = [];
      data.data.map((record) => {
        let variant = {
          name: `${record.name} / ${record.variant.combination.join(" / ")}`,
          ...record.variant,
        };
        variant.productId = record._id;
        pros.push(variant);
      });
      setProducts(pros);
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  useEffect(() => {
    getVariantProducts();
  }, []);

  const onChangePurchaseProduct = (items) => {
    const itemValues = items.map((item) => item.value);
    const tempProduct = products.filter((product) =>
      itemValues.includes(product._id),
    );

    const discountTypeMap = discountType.reduce((map, type) => {
      map[type._id] = type.key;
      return map;
    }, {});
    const updatedTempProduct = tempProduct.map((product) => ({
      ...product,
      discountTypeKey: discountTypeMap[product.discountTypeId] || null,
    }));
    form.setFieldsValue({ compositeProducts: updatedTempProduct });
  };
  return (
    <div>
      <br />
      <Form.Item
        name="compositeProductsIds"
        rules={[
          {
            required: true,
            message: "Please input the composite product name!",
          },
        ]}
      >
        <Select
          placeholder="Select an option"
          onChange={onChangePurchaseProduct}
          labelInValue
          mode="tags"
        >
          {dropDownPayload(products).map((option) => (
            <Option key={option._id} value={option._id}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <PurchasePricing form={form} />
    </div>
  );
}

export default ComponentProduct;
