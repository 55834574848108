import React, { useEffect, useState } from "react";
import { dropDownPayloadCustomer } from "../../utils/helper";
import { Button, Form, Select, Input } from "antd";
function AddNewCustomerModal({
  customerForm,
  onClickAdd,
  onFinishCustomerModal,
  customer,
  setSelectedCustomerName,
}) {
  return (
    <>
      <Form onFinish={onFinishCustomerModal} form={customerForm}>
        <Form.Item
          name="customerName"
          rules={[{ required: false, message: "This is required" }]}
        >
          <Input
            className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
            placeholder="Enter Customer Name"
          />
        </Form.Item>

        <Form.Item
          name="customerContactNo"
          rules={[{ required: false, message: "This is required" }]}
        >
          <Input
            className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
            placeholder="Enter Contact No"
          />
        </Form.Item>

        <span className="block text-left text-lightGrey font-bold my-2">
          Or Select Existing Customer
        </span>

        <Form.Item
          name="customerId"
          className="flex-1"
          rules={[{ required: false, message: "Please enter state" }]}
        >
          <Select
            showSearch
            className="w-[100%] h-auto"
            options={dropDownPayloadCustomer(customer)}
            placeholder="Select Customer"
            onChange={(value, option) => setSelectedCustomerName(option.label)}
          />
        </Form.Item>
        <div className="flex items-center justify-center gap-3 mt-7">
          <Button
            type="submit"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Confirm
          </Button>
        </div>
        <span className="block text-center text-lightGrey font-bold my-2">
          Or
        </span>
        <div className="flex items-center justify-center">
          <Button
            type="primary"
            onClick={onClickAdd}
            htmlType="button"
            className="h-auto cursor-pointer leading-none flex py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Add Customer
          </Button>
        </div>
      </Form>
    </>
  );
}

export default AddNewCustomerModal;
