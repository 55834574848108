import React, { useEffect, useState } from "react";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import { Form } from "antd";
import EditStore from "../../component/outlet/editStore";
import { updateStore } from "../../services/outlet";
import {
  getAllListOfValueByKey,
  updateLov,
} from "../../services/globalService";
import AddLoyality from "../../component/outlet/addLoyality";

function Loyality() {
  const [form] = Form.useForm();
  const [lov, setLov] = useState([]);
  const getLoyality = async () => {
    try {
      const { data: response } = await getAllListOfValueByKey("loyality_point");
      const { data: record } = response;
      setLov(record.data);
      let loyality_amount_on = 0;
      let loyality_points_on = 0;
      record.data.map((res) => {
        if (res.key == "loyality_points_on") {
          loyality_points_on = res.value;
        } else if (res.key == "loyality_amount_on") {
          loyality_amount_on = res.value;
        }
      });
      form.setFieldsValue({
        loyality_amount_on,
        loyality_points_on,
      });
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLoyality();
  }, []);

  const onFinish = async (values) => {
    try {
      let amountId;
      let pointId;
      lov.forEach((item) => {
        if (item.key === "loyality_amount_on") {
          amountId = item._id;
        }
        if (item.key === "loyality_points_on") {
          pointId = item._id;
        }
      });
      const loyalityAmountOn = await updateLov(amountId, {
        value: Number(values.loyality_amount_on),
      });
      const loyalityPointsOn = await updateLov(pointId, {
        value: Number(values.loyality_points_on),
      });
      if (
        loyalityAmountOn.data.meta.success &&
        loyalityPointsOn.data.meta.success
      ) {
        openNotification("success", "Loyality points successfully updated");
      } else {
        openNotification("error", loyalityAmountOn.data.error);
        openNotification("error", loyalityPointsOn.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };

  return (
    <main className="mt-6 flex-1 flex flex-col overflow-hidden">
      <div className="p-6 bg-white rounded-3xl h-full flex flex-col">
        <div className="flex items-center justify-between mb-8 text-left">
          <h2 className="text-2xl font-medium flex-1">Loyality</h2>
        </div>
        <div className="flex flex-col justify-between overflow-auto scrollbar">
          <AddLoyality form={form} onFinish={onFinish} />
        </div>
      </div>
    </main>
  );
}

export default Loyality;
