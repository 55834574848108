import React from "react";

function SaleSummary({ registerData }) {
  // Helper function to safely format numbers and handle NaN
  const formatNumber = (value) => {
    const number = Number(value);
    return isNaN(number) ? "0" : number.toFixed(2);
  };

  return (
    <>
      <table className="w-full">
        <tbody>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Total Sales
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {formatNumber(registerData?.transaction?.total)}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              On Account Sale
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {formatNumber(registerData?.transaction?.onAccountSale)}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Item Discounts
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {formatNumber(registerData?.transaction?.itemDiscount)}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Order Discounts
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {formatNumber(registerData?.transaction?.orderDiscount)}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Surcharge
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {formatNumber(registerData?.transaction?.surcharge)}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              Avg Sale Volume
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {formatNumber(registerData?.transaction?.avgSalesVolume)}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
              No Of Transactions
            </td>
            <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
              {formatNumber(registerData?.transaction?.totalTransactions)}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

export default SaleSummary;
