import React, { useState } from "react";
import { Form, Input, Row, Col, Button } from "antd";

function AddLoyality({ form, onFinish }) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="flex flex-wrap gap-4">
          <div className="w-[calc(50%_-_1rem)]">
            <Form.Item
              name="loyality_amount_on"
              label={
                <span className="text-lightGrey font-medium">
                  Loyality Amount
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="number"
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Amount"
              />
            </Form.Item>
          </div>
          <div className="w-[calc(50%_-_1rem)]">
            <Form.Item
              name="loyality_points_on"
              label={
                <span className="text-lightGrey font-medium">
                  Loyality Points
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                type="number"
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter Points"
              />
            </Form.Item>
          </div>
        </div>
        <div className="flex items-center justify-end">
          <div className="flex items-center justify-center gap-3">
            <Button
              type="primary"
              htmlType="submit"
              className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
            >
              Save
            </Button>
          </div>
        </div>
      </Form>
    </>
  );
}

export default AddLoyality;
