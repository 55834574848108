import React, { useState } from "react";
import { Form, Input, Button, Upload, Row, Col, message, Select } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import CustomSelect from "../shared/customSelect/indes";
import { API_BASE_URL } from "../shared/contants";

function OpenRegister({ form, onFinish }) {
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Form
        form={form}
        name="productDetailsForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="openingBalance"
              label={
                <span className="text-lightGrey font-medium">
                  Opening Balance
                </span>
              }
              rules={[{ required: true, message: "This is required" }]}
            >
              <Input
                className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
                placeholder="Enter opening balance"
              />
            </Form.Item>

            {/* Description */}
            <Form.Item
              name="notes"
              label={<span className="text-lightGrey font-medium">Notes</span>}
            >
              <Input.TextArea
                rows={3}
                className="block w-full border focus:border-ufoGreen rounded-lg py-3.5 px-6"
                placeholder="Enter notes"
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Submit Button */}
        <div className="flex items-center justify-center gap-3 mt-7">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Open
          </Button>
        </div>
      </Form>
    </>
  );
}

export default OpenRegister;
