import { Form, Input, Select, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAllTaxApi } from "../../../services/product/tax";
import { getAllListOfValueByKey } from "../../../services/globalService";

function ProductListing({ form }) {
  const [tax, setTax] = useState([]);
  const [discountType, setDiscountType] = useState([]);

  const { Option } = Select;
  const getAllTax = async () => {
    try {
      const response = await getAllTaxApi("tax");
      setTax(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllTax();
  }, []);

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("discount_type");
      setDiscountType(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
  }, []);

  const getTaxRate = (taxId) => {
    const tempTax = tax.find((data) => data._id == taxId);
    return tempTax?.rate || 0;
  };
  const onChangeDiscountType = (value, name) => {
    const values = form.getFieldsValue();
    const transferDetails = values.transferDetails[name];
    form.setFieldsValue({
      transferDetails: {
        ...values.transferDetails,
        [name]: {
          ...transferDetails,
          quantity: value,
        },
      },
    });
  };
  return (
    <div className="flex flex-col gap-6 mt-6">
      <Form.List name="transferDetails">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div className="flex gap-4 items-start" key={key}>
                {/* Discount */}
                <Form.Item
                  {...restField}
                  name={[name, "price"]}
                  fieldKey={[fieldKey, "price"]}
                  label="Price"
                  className="flex-1"
                >
                  <Input
                    disabled
                    type="number"
                    defaultValue={"0.0"}
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "quantity"]}
                  fieldKey={[fieldKey, "quantity"]}
                  label="Quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please enter quantity",
                    },
                  ]}
                  className="flex-1"
                >
                  <Input
                    placeholder="0.00"
                    onChange={(e) => onChangeDiscountType(e.target.value, name)}
                    type="number"
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
}

export default ProductListing;
