import axios from "axios";
import { setupCache } from "axios-cache-interceptor";
import { store } from "../redux/store";
import { FinishLoading, TotalRequest } from "../redux/feature/loader";
import { API_BASE_URL } from "../component/shared/contants";

const Api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

Api.interceptors.request.use(
  async (config) => {
    await store?.dispatch(TotalRequest());
    config.headers["access-key"] = "12345";
    config.headers["authorization"] =
      `Bearer ${localStorage.getItem("accessToken")}`;

    return config;
  },
  async (error) => {
    await store?.dispatch(FinishLoading());
    return Promise.reject(error);
  },
);

Api.interceptors.response.use(
  async (response) => {
    await store?.dispatch(FinishLoading());
    const { meta } = response.data;
    if (meta?.statusCode == 201 || meta?.statusCode == 200) {
      response.data.success = true;
    }
    return response;
  },
  async (error) => {
    const res = error?.response;
    const AUTH_DISABLED_NOTIFICATION_ENDPOINTS = [
      "users/me",
      "users/verify-hash",
    ];
    await store?.dispatch(FinishLoading());
    let message =
      res?.data.message || res?.data?.meta?.message || res?.data?.error;

    if (Array.isArray(res?.data?.meta?.message)) {
      message = res?.data?.meta?.message[0];
    }
    if (
      !(
        window.location.href.includes("auth") &&
        AUTH_DISABLED_NOTIFICATION_ENDPOINTS.some((endpoint) =>
          res.request.responseURL.includes(endpoint),
        )
      )
    ) {
      console.log(message);
    }
    res.data = { ...res?.data, success: false };
    if (res?.status === 401 && !window.location.href.includes("/")) {
      window.location.href = "/";
      return res;
    } else if (res?.status === 410) {
      window.location.href = "/dashboard";
      return res;
    }
    return res;
  },
);

export default Api;
