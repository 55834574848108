import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getSelectedOutlet } from "../../../redux/feature/outletSlice";
import {
  getRegisterDetailsApi,
  openRegister,
} from "../../../services/register";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../../component/shared/searchInput";
import CustomTable from "../../../component/shared/customTable";
import CustomModal from "../../../component/shared/modal";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { Form, Button } from "antd";
import OpenRegister from "../../../component/register/openRegister";

function Registerlisting() {
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [register, setRegister] = useState(null);
  const navigate = useNavigate();

  const getRegisterDetails = async () => {
    try {
      const { data: response } = await getRegisterDetailsApi(
        selectedOutlet._id,
      );
      if (response.data._id) {
        navigate(
          `../pos/cash-register-record?search=${response.data.cashRegister._id}`,
          { state: { id: response.data._id } },
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getRegisterDetails();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Receipt No",
      dataIndex: "receiptNo",
      key: "receiptNo",
    },
    {
      title: "Receipt Prefix",
      dataIndex: "receiptPrefix",
      key: "receiptPrefix",
    },
    {
      title: "Receipt Suffix",
      dataIndex: "receiptSuffix",
      key: "receiptSuffix",
    },
    {
      title: "Outlet",
      dataIndex: "outlet",
      key: "outlet",
      render: (_, row) => {
        return row?.outlet?.name;
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, row) => <p>{row.createdBy.fullName}</p>,
    },
    {
      title: "Action",
      render: (_, row) => (
        <Button
          type="primary"
          htmlType="submit"
          className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          onClick={() => onClickOpen(row)}
        >
          Open Register
        </Button>
      ),
    },
  ];

  const onClickOpen = async (row) => {
    setRegister(row);
    openModal();
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
  };

  const onFinish = async (values) => {
    let response;
    try {
      const payload = {
        ...values,
        openingBalance: Number(values.openingBalance),
        closingNotes: values.notes,
      };
      if (register) {
        response = await openRegister(payload, register._id);
      }
      if (response.data.meta.success) {
        navigate(
          `../pos/cash-register-record?search=${response.data.data.cashRegisterId}`,
        );
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Register successfully opened");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  return (
    <main className="mt-4">
      <CustomModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        heading={"Open Register"}
      >
        <OpenRegister form={form} onFinish={onFinish} />
      </CustomModal>
      <div className="mt-4 px-6 py-8 bg-white rounded-3xl">
        <div className="flex items-center justify-between mb-8 text-left">
          <h2 className="text-2xl font-medium flex-1">Register</h2>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between min-h-96">
          <CustomTable
            refreshTable={refreshTable}
            columns={columns}
            endPoint={"cash-register"}
            search={searchText}
            // handleStatus={onClickStatus}
            // handleDelete={onClickDelete}
            // handleEdit={onClickOpen}
            showActions={false}
          />
        </div>
      </div>
    </main>
  );
}

export default Registerlisting;
