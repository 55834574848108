import React, { useEffect, useState } from "react";
import BasicCard from "../../../component/shared/basicCard/basicCard";
import Payment from "../../../component/pos/cashRegister/payment";
import SaleSummary from "../../../component/pos/cashRegister/saleSummary";
import CashInOut from "../../../component/pos/cashRegister/cashInOut";
import PaymentSymmary2 from "../../../component/pos/cashRegister/paymentSummary2";
import TaxSummary from "../../../component/pos/cashRegister/taxSummary";
import { useSelector } from "react-redux";
import {
  closeRegister,
  getRegisterDetailsApi,
} from "../../../services/register";
import { getSelectedOutlet } from "../../../redux/feature/outletSlice";
import { Form } from "antd";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { useLocation, useNavigate } from "react-router-dom";

function CashRegister() {
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  const navigate = useNavigate();
  const [cashDifference, setCashDifference] = useState("");
  const [cardDifference, setCardDifference] = useState("");
  const [creditDifference, setCreditDifference] = useState("");
  const location = useLocation();
  const [registerData, setRegisterData] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const registerId = queryParams.get("search") || "";
  const [form] = Form.useForm();
  const getRegisterDetails = async () => {
    try {
      const { data: response } = await getRegisterDetailsApi(
        selectedOutlet._id,
      );
      if (response.meta.success) {
        setRegisterData(response.data);
        form.setFieldsValue({
          countedCash: response.data.transaction.cash,
          countedCard: response.data.transaction.card,
          countedCredit: response.data.transaction.credit,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getRegisterDetails();
  }, []);

  const onValuesChange = (changedValues, allValues) => {
    const { countedCard, countedCash, countedCredit } = allValues;
    setCashDifference(
      Number(countedCash) - Number(registerData?.transaction?.cash || 0),
    );
    setCardDifference(
      Number(countedCard) - Number(registerData?.transaction?.card || 0),
    );
    setCreditDifference(
      Number(countedCredit) - Number(registerData?.transaction?.credit || 0),
    );
  };
  const onFinish = async (values) => {
    let response;
    try {
      const payload = {
        ...values,
        isActive: true,
      };
      response = await closeRegister(registerId, payload, registerData._id);
      if (response.data.meta.success) {
        openNotification("success", "Register successfully closed");
        navigate("../../pos/cash-register");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  return (
    <main className="mt-4 flex-1 flex flex-col overflow-hidden">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-medium">Cash Register</h2>
        <p className="text-lg font-light">
          <strong className="font-medium">Opened:</strong>{" "}
          {registerData?.startTime
            ? `${new Date(registerData.startTime).toLocaleDateString()} - ${new Date(registerData.startTime).toLocaleTimeString()}`
            : "No Date Available"}
        </p>
      </div>
      <Form
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        className="grid gap-8 grid-cols-12 flex-1 overflow-y-scroll scrollbar"
      >
        <div className="col-span-12 xl:!col-span-6">
          <BasicCard heading={"Payment Tally"}>
            <Payment
              registerData={registerData}
              cashDifference={cashDifference}
              cardDifference={cardDifference}
              creditDifference={creditDifference}
            />
          </BasicCard>
          <BasicCard heading={"Sale Summary"}>
            <SaleSummary registerData={registerData} />
          </BasicCard>
        </div>
        <div className="col-span-12 xl:!col-span-6">
          <BasicCard heading={"Cash IN/OUT"}>
            <CashInOut registerData={registerData} registerId={registerId} />
          </BasicCard>
          <BasicCard heading={"Payment Summary"}>
            <PaymentSymmary2 registerData={registerData} />
          </BasicCard>

          <BasicCard heading={"Tax Summary"}>
            <TaxSummary registerData={registerData} />
          </BasicCard>

          <div className="flex items-center justify-end mt-6 gap-3">
            <button
              type="primary"
              htmlType="submit"
              className="leading-none block py-4 px-6 bg-redOrange rounded-full text-white font-medium border border-redOrange cursor-pointer"
            >
              Close Register
            </button>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default CashRegister;
