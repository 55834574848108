// Local
// export const API_BASE_URL = "http://localhost:3001/api/v1";

// heroku
// export const API_BASE_URL =
//   "https://poxfy-api-671911087a12.herokuapp.com/api/v1";

// export const API_BASE_URL ="https://ameer.poxfy.com/api/v1";

// client server alma linux
export const API_BASE_URL = "/api/v1";

export const Days = [
  { dayId: "monday", dayName: "Monday" },
  { dayId: "tuesday", dayName: "Tuesday" },
  { dayId: "wednesday", dayName: "Wednesday" },
  { dayId: "thursday", dayName: "Thursday" },
  { dayId: "friday", dayName: "Friday" },
  { dayId: "saturday", dayName: "Saturday" },
  { dayId: "sunday", dayName: "Sunday" },
];

export const DaysName = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

export const PaymentStatus = [
  {
    label: "PARKED",
    value: "PARKED",
  },
  {
    label: "PAID",
    value: "PAID",
  },
];

export const productType = [
  {
    id: 1,
    value: "NO_VARIANT",
    label: "Simple Product, No Variants",
  },
  {
    id: 2,
    value: "PRODUCT_WITH_VARIANT",
    label: "Product With Variants",
  },
  {
    id: 3,
    value: "COMPOSITE_PRODUCT",
    label: "Composite Product",
  },
];

export const inventoryType = [
  {
    id: 1,
    value: "ALL",
    label: "All",
  },
  {
    id: 2,
    value: "LOW_INVENTORY",
    label: "Low Inventory",
  },
  {
    id: 3,
    value: "ZERO_ITEMS",
    label: "Zero Items",
  },
  {
    id: 4,
    value: "GREATER_ZERO_ITEMS",
    label: "Greater Zero Items",
  },
];
