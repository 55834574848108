import React, { useState, useEffect } from "react";
import { Form, Input, InputNumber, Select, Row, Col } from "antd";
import { getAllOutletApi } from "../../../services/outlet";
import {
  getAllProductInventorry,
  updateAllProductInventorry,
} from "../../../services/inventory";
import { dropDownPayload } from "../../../utils/helper";
import { getAllProduct } from "../../../services/product";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";

function ProductOpeningBalance() {
  const [form] = Form.useForm();
  const [products, setProducts] = useState([]);
  const [outlet, setOutlet] = useState([]);
  const [selectedOutlet, setSelectedOutlet] = useState({});

  const getOutlet = async () => {
    try {
      const { data: response } = await getAllOutletApi();
      const { data: record } = response;
      setOutlet(record.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getProductsForInventory = async (id) => {
    try {
      const {
        data: { data },
      } = await getAllProductInventorry(id);
      setProducts(data);

      const productResponse = await getAllProduct();
      const products = productResponse?.data?.data.data || [];

      const inventory = products.map((item) => {
        const inventory = data?.find((prod) => prod.productId === item._id);
        return {
          _id: item._id,
          productId: `${item.name} - ${item.skuNo || "N/A"} - ${item.barcode}`,
          name: item.name,
          skuNo: item.skuNo,
          barcode: item.barcode,
          quantity: inventory?.quantity || 0,
        };
      });
      form.setFieldsValue({ products: inventory });
    } catch {
      console.error("Failed to fetch products.");
    }
  };

  useEffect(() => {
    getOutlet();
  }, []);

  useEffect(() => {
    if (selectedOutlet.value) {
      getProductsForInventory(selectedOutlet._id);
    }
  }, [selectedOutlet]);

  const onClickUpdate = async () => {
    const value = form.getFieldsValue();
    const updatedPayload = value.products.map((data) => ({
      productId: data._id,
      quantity: data.quantity,
    }));
    try {
      const response = await updateAllProductInventorry(
        updatedPayload,
        selectedOutlet._id,
      );
      if (response.data.meta.success) {
        openNotification("success", "Updated Successfully");
      } else {
        openNotification("error", response.data.error);
      }
    } catch {
      ServerError();
    }
  };
  return (
    <main className="mt-6 text-left flex-1 overflow-y-scroll scrollbar">
      <div className="px-6 py-8 bg-white rounded-3xl overflow-hidden flex flex-col h-full">
        <div className="flex items-center justify-between mb-4 xl:!mb-8 text-left">
          <h2 className="text-2xl font-medium">Product Opening Balances</h2>
        </div>
        <Row>
          <Col span={8}>
            <Select
              className="w-full h-10 mb-3"
              options={dropDownPayload(outlet)}
              onChange={(e, value) => setSelectedOutlet(value)}
              placeholder={"Select Outlet"}
            />
          </Col>
          <Col span={8}></Col>
          <Col span={8}>
            <Form.Item className="flex justify-end">
              <button
                type="submit"
                className="w-[200px] flex justify-center block capitalize py-2 px-4 bg-redOrange text-white rounded-full"
                onClick={onClickUpdate}
              >
                Update
              </button>
            </Form.Item>
          </Col>
        </Row>
        <Form
          className="overflow-y-scroll scrollbar"
          form={form}
          name="productForm"
          autoComplete="off"
          layout="vertical"
        >
          <table className="w-full">
            <thead>
              <tr className="bg-veryLightGrey">
                <th className="font-medium text-sm py-2 border-b border-lightBlue text-center">
                  Name
                </th>
                <th className="font-medium text-sm py-2 border-b border-lightBlue text-center">
                  Opening
                </th>
              </tr>
            </thead>
            <tbody>
              <Form.List name="products">
                {(fields) =>
                  fields.map(({ key, name, fieldKey, ...restField }) => (
                    <tr key={key}>
                      <td className="text-center font-light text-sm py-2 border-b border-lightBlue">
                        <Form.Item
                          {...restField}
                          name={[name, "productId"]}
                          fieldKey={[fieldKey, "productId"]}
                          rules={[
                            {
                              required: true,
                              message: "Product ID is required",
                            },
                          ]}
                        >
                          <Input
                            disabled
                            style={{
                              border: "none",
                              background: "white",
                              color: "black",
                            }}
                            placeholder="Product ID"
                          />
                        </Form.Item>
                      </td>
                      <td className="text-center font-light text-sm py-2 border-b border-lightBlue">
                        <Form.Item
                          className="flex justify-center"
                          {...restField}
                          name={[name, "quantity"]}
                          fieldKey={[fieldKey, "quantity"]}
                          rules={[
                            { required: true, message: "Quantity is required" },
                          ]}
                        >
                          <InputNumber
                            min={0}
                            placeholder="Quantity"
                            className="w-[100px]"
                          />
                        </Form.Item>
                      </td>
                    </tr>
                  ))
                }
              </Form.List>
            </tbody>
          </table>
        </Form>
      </div>
    </main>
  );
}

export default ProductOpeningBalance;
