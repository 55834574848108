import React, { useEffect, useState } from "react";
import BasicCard from "../../../component/shared/basicCard/basicCard";
import { Form } from "antd";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllOutletApi } from "../../../services/outlet";
import { getAllCustomerApi } from "../../../services/customer";
import InventoryProductsAdd from "../../../component/inventory/addInventoryTransfer/inventoryProductsAdd";
import {
  createinventoryTransfer,
  getinventoryTransferById,
  updateinventoryTransfer,
} from "../../../services/inventory/transfer";
import Information from "../../../component/inventory/addInventoryTransfer/information";

function AddInventory() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState(location.state?.id ? location?.state?.id : null);
  const [outlet, setOutlet] = useState(null);
  const [customer, setCustomer] = useState(null);

  const getInvntoryDetails = async (id) => {
    try {
      const {
        data: { data },
      } = await getinventoryTransferById(id);
      if (data) {
        const productIds = data.transferDetails.map((val) => val._id);
        form.setFieldValue("transferDetailsIds", productIds);
        form.setFieldValue("transferDetails", data.transferDetails);
        form.setFieldsValue(data);
      }
    } catch (err) {
      console.log({ err });
      ServerError();
    }
  };

  useEffect(() => {
    if (id) {
      getInvntoryDetails(id);
    }
  }, []);

  const getListOfValues = async () => {
    try {
      const [getAllOutletRes, customerRes] = await Promise.all([
        getAllOutletApi(),
        getAllCustomerApi(),
      ]);
      setOutlet(getAllOutletRes.data.data.data);
      setCustomer(customerRes.data.data.data);
    } catch (err) {
      console.error("Error fetching data", { err });
    }
  };

  useEffect(() => {
    getListOfValues();
  }, []);

  const onFinish = async (values) => {
    let response;
    let payload;
    try {
      payload = {
        ...values,
        isActive: true,
        transferDetails: values.transferDetails,
      };
      delete payload.transferDetailsIds;
      if (id) {
        response = await updateinventoryTransfer(payload, id);
      } else {
        response = await createinventoryTransfer(payload);
      }
      if (response.data.meta.success) {
        form.resetFields();
        openNotification("success", "Inventory added successfully");
        navigate("../invenroty/inventor-transfer");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log({ err });
      ServerError();
    }
  };

  return (
    <main className="mt-6 text-left flex-1 flex flex-col overflow-hidden">
      <Form
        form={form}
        name="descriptionForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
        className="flex-1 overflow-y-scroll scrollbar"
      >
        <div className="grid gap-8 grid-cols-12 pb-24">
          <div className="col-span-12">
            <BasicCard heading={"Add Inventory"} className="addProductInfo">
              <Information outlet={outlet} customer={customer} />
            </BasicCard>

            <BasicCard heading={"Products"}>
              <InventoryProductsAdd form={form} />
            </BasicCard>
          </div>
          <div className="col-span-12">
            <div className="flex items-center justify-end gap-3">
              <Form.Item>
                <input
                  type="button"
                  onClick={() => navigate("../purchase")}
                  defaultValue="Cancel"
                  className="leading-none block py-4 px-10 bg-veryLightGrey rounded-full text-lightGrey font-medium border border-lightGrey"
                />
              </Form.Item>
              <Form.Item>
                <input
                  type="submit"
                  defaultValue="Save"
                  className="leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default AddInventory;
