import Api from "../../../network/client";

export const changeTenantStatus = async (id, data) => {
  return Api.put(`tenant/${id}`, data);
};
export const createTenant = async (data) => {
  return Api.post(`tenant`, data);
};
export const updateTenant = async (data, id) => {
  return Api.put(`tenant/${id}`, data);
};
export const getAllTenantApi = async () => {
  return Api.get(`tenant`);
};
export const getAllTenantPermissionsApi = async () => {
  return Api.get(`tenant`);
};
export const deleteTenantApi = async (id) => {
  return Api.delete(`tenant/${id}`);
};
export const mySubscription = async (id) => {
  return Api.get(`me/subscription`);
};
