import Api from "../../../network/client";

export const getAllAttributeApi = async () => {
  return Api.get(`inventory-transfer/attributes`);
};
export const getAllinventoryTransfer = async () => {
  return Api.get(`inventory-transfer`);
};
export const getAllVariantinventoryTransfer = async () => {
  return Api.get(`inventory-transfer/by-variants`);
};
export const getinventoryTransferById = async (id) => {
  return Api.get(`inventory-transfer/${id}`);
};
export const createinventoryTransfer = async (data) => {
  return Api.post(`inventory-transfer`, data);
};
export const updateinventoryTransfer = async (data, id) => {
  return Api.put(`inventory-transfer/${id}`, data);
};

export const deleteinventoryTransferById = async (id) => {
  return Api.delete(`inventory-transfer/${id}`);
};
export const getAllinventoryTransfersByTypeId = async (id, searchText) => {
  return Api.get(
    `inventory-transfer?inventory-transferTypeIds=${id}&name=${searchText}`,
  );
};
