export const validationRules = {
  "Code 128 (C128)": /^[\x20-\x7E]+$/, // Code 128: Any printable ASCII characters
  "Code 39 (C39)": /^[A-Z0-9\-\.\ \$\/\+\%]+$/, // Code 39: Alphanumeric with specific characters
  "EAN-13": /^\d{13}$/, // EAN-13: Exactly 13 digits
  "EAN-8": /^\d{8}$/, // EAN-8: Exactly 8 digits
  "UPC-A": /^\d{12}$/, // UPC-A: Exactly 12 digits
  "UPC-E": /^\d{8}$/, // UPC-E: Exactly 8 digits
};
export const barcodeHints = {
  "Code 128 (C128)": "Code 128 allows alphanumeric characters.",
  "Code 39 (C39)":
    "Code 39 allows uppercase letters, digits, and some special characters.",
  "EAN-13": "EAN-13 must be exactly 13 digits long.",
  "EAN-8": "EAN-8 must be exactly 8 digits long.",
  "UPC-A": "UPC-A must be 12 digits long.",
  "UPC-E": "UPC-E must be 6 digits long.",
};
