import { Form } from "antd";
import React from "react";

function Payment({
  registerData,
  cashDifference,
  cardDifference,
  creditDifference,
}) {
  const { transaction } = registerData || {};
  const safeNumber = (value) =>
    isNaN(Number(value)) ? 0 : Number(value).toFixed(2);
  return (
    <>
      <table className="payment-tally-tbl w-full p-0">
        <thead>
          <tr>
            <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
              Payment
            </th>
            <th className="font-medium text-sm py-2 border-b border-lightBlue text-right">
              Expected
            </th>
            <th className="font-medium text-sm py-2 border-b border-lightBlue text-right">
              Counted
            </th>
            <th className="font-medium text-sm py-2 border-b border-lightBlue text-right">
              Difference
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className="text-left font-light text-sm border-b border-lightBlue">
              Cash
            </td>
            <td className="text-right font-light text-sm border-b border-lightBlue">
              {safeNumber(transaction?.cash)}
            </td>
            <td className="text-right font-light text-sm border-b border-lightBlue">
              <Form.Item name="countedCash" className="flex-1">
                <input
                  type="text"
                  placeholder="0.00"
                  className="block leading-none py-3 px-6 border border-lightGrey ml-auto text-right mt-2 rounded-full outline-0"
                />
              </Form.Item>

              <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
              <div
                id="cashCountPopup"
                className="fixed text-left bg-white rounded-l-3xl p-6 -right-96 transition-[right] [&.active]:transition-[right] transition-all duration-150 ease-in-out [&.active]:right-0 z-20 top-2/4 -translate-y-2/4 min-w-96 min-h-[80%]"
              >
                <div className="flex items-center justify-start border-b pb-7 mb-5">
                  <svg
                    className="cashCountPopupClose mr-10 cursor-pointer"
                    width={8}
                    height={14}
                    viewBox="0 0 9 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8 1L1 7.99646L8 14.9929"
                      stroke="#FE5E5E"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <h3 className="font-medium text-2xl">Cash Count</h3>
                </div>
                <label className="flex items-center justify-between text-center border rounded-full py-4 px-6 mt-10 leading-none">
                  <span className="block text-md font-medium flex-1">
                    Total
                  </span>
                  <span className="block text-md font-medium flex-1">=</span>
                  <input
                    type="text"
                    defaultValue="Rs0"
                    className="w-16 text-md text-center font-medium  flex-1 outline-0"
                  />
                </label>
                <div className="flex items-center justify-center gap-3 mt-10    ">
                  <input
                    type="submit"
                    defaultValue="Cancel"
                    className="cursor-pointer leading-none block py-4 px-10 bg-veryLightGrey rounded-full text-lightGrey font-medium border border-lightGrey"
                  />
                  <input
                    type="submit"
                    defaultValue="Save"
                    className="cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
                  />
                </div>
              </div>
            </td>
            <td className="text-right font-light text-sm border-b border-lightBlue">
              {Number(cashDifference).toFixed(2)}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm border-b border-lightBlue">
              Card
            </td>
            <td className="text-right font-light text-sm border-b border-lightBlue">
              {transaction?.card || 0}
            </td>
            <td className="text-right font-light text-sm border-b border-lightBlue">
              <Form.Item name="countedCard" className="flex-1">
                <input
                  type="text"
                  placeholder="0.00"
                  className="block leading-none py-3 px-6 ml-auto text-right border border-lightGrey mt-2 rounded-full outline-0"
                />
              </Form.Item>
            </td>
            <td className="text-right font-light text-sm border-b border-lightBlue">
              {Number(cardDifference).toFixed(2) || 0}
            </td>
          </tr>
          <tr>
            <td className="text-left font-light text-sm border-b border-lightBlue">
              Store Credit
            </td>
            <td className="text-right font-light text-sm border-b border-lightBlue">
              {transaction?.credit || 0}
            </td>
            <td className="text-right font-light text-sm border-b border-lightBlue">
              <Form.Item name="countedCredit" className="flex-1">
                <input
                  type="text"
                  placeholder="0.00"
                  className="block leading-none py-3 px-6 ml-auto text-right border border-lightGrey mt-2 rounded-full outline-0"
                />
              </Form.Item>
            </td>
            <td className="text-right font-light text-sm border-b border-lightBlue">
              {creditDifference || 0}
            </td>
          </tr>
        </tbody>
      </table>
      <div className="mt-6 relative">
        <div className="flex items-center justify-start mb-4">
          <svg
            className="mr-2"
            width={20}
            height={19}
            viewBox="0 0 20 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19.2865 1.29032H15.7692V0.703812C15.7692 0.322581 15.4468 0 15.0657 0C14.6847 0 14.3623 0.322581 14.3623 0.703812V1.29032H5.7156V0.703812C5.7156 0.322581 5.39318 0 5.01214 0C4.6311 0 4.30869 0.322581 4.30869 0.703812V1.29032H0.703459C0.322419 1.29032 0 1.6129 0 1.99413V17.7713C0 18.1525 0.322419 18.4751 0.703459 18.4751H19.2865C19.6675 18.4751 19.99 18.1525 19.99 17.7713V1.99413C19.99 1.6129 19.6675 1.29032 19.2865 1.29032ZM4.30869 2.69795V3.07918C4.30869 3.46041 4.6311 3.78299 5.01214 3.78299C5.39318 3.78299 5.7156 3.46041 5.7156 3.07918V2.69795H14.3623V3.07918C14.3623 3.46041 14.6847 3.78299 15.0657 3.78299C15.4468 3.78299 15.7692 3.46041 15.7692 3.07918V2.69795H18.5537V4.51613H1.40692V2.69795H4.30869ZM1.40692 17.0674V5.95308H18.583V17.0674H1.40692Z"
              fill="#00E37D"
            />
            <path
              d="M15.0657 9.17871H4.92416C4.54312 9.17871 4.2207 9.50129 4.2207 9.88252C4.2207 10.2638 4.54312 10.5863 4.92416 10.5863H15.095C15.476 10.5863 15.7985 10.2638 15.7985 9.88252C15.7985 9.50129 15.476 9.17871 15.0657 9.17871Z"
              fill="#00E37D"
            />
            <path
              d="M9.99493 12.6104H4.92416C4.54312 12.6104 4.2207 12.9329 4.2207 13.3142C4.2207 13.6954 4.54312 14.018 4.92416 14.018H9.99493C10.376 14.018 10.6984 13.6954 10.6984 13.3142C10.6984 12.9036 10.376 12.6104 9.99493 12.6104Z"
              fill="#00E37D"
            />
          </svg>
          <span className="text-ufoGreen text-sm font-medium">Notes</span>
        </div>
        <Form.Item name="notes" className="flex-1">
          <input
            type="text"
            placeholder="Notes"
            className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
          />
        </Form.Item>
      </div>
    </>
  );
}

export default Payment;
