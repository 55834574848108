import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Select, Tooltip, Row, Col, Button } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { inventoryType, productType } from "../../../component/shared/contants";
import { getAllOutletApi } from "../../../services/outlet";
import { dropDownPayload } from "../../../utils/helper";
import {
  getAllProductAddAndSet,
  updateAllProductInventorry,
  updateInventorryLedger,
} from "../../../services/inventory";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";

function InventoryAddSet() {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const [outlet, setOutlet] = useState([]);
  const [allRecord, setRecord] = useState([]);

  const [filter, setFilter] = useState({
    productType: "",
    outletId: "",
    inventoryType: "",
  });

  const getOutlet = async () => {
    try {
      const { data: response } = await getAllOutletApi();
      const { data: record } = response;
      setOutlet(record.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getOutlet();
  }, []);

  const generateQueryParams = (obj) => {
    return Object.entries(obj)
      .filter(([key, value]) => value) // Exclude keys with empty values
      .map(
        ([key, value]) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(value)}`,
      ) // Encode key-value pairs
      .join("&"); // Join them with "&" to form the query string
  };

  const getAddData = async () => {
    try {
      const { data: response } = await getAllProductAddAndSet(
        generateQueryParams(filter),
      );
      const { data: record } = response;
      setRecord(record);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAddData();
  }, [filter, refresh]);

  const handleEdit = (value, recordKey, columnKey) => {
    setRecord((prevData) =>
      prevData.map((row) =>
        row.productId === recordKey.productId
          ? {
              ...row,
              [columnKey]: value,
            }
          : row,
      ),
    );
  };

  const onClickSet = async (record) => {
    if (!record.extraQuantity) {
      openNotification("error", "Quantity is not added");
    } else {
      try {
        const updatedPayload = {
          productId: record.productId,
          quantity: record.extraQuantity,
        };
        const response = await updateAllProductInventorry(
          [updatedPayload],
          filter.outletId,
        );
        if (response.data.meta.success) {
          setRefresh(!refresh);
          openNotification("success", "Updated Successfully");
        } else {
          openNotification("error", response.data.error);
        }
      } catch (err) {
        console.log(err);
        ServerError();
      }
    }
  };
  const onClickAdd = async (record) => {
    if (!record.extraQuantity) {
      openNotification("error", "Quantity is not added");
    } else {
      try {
        const updatedPayload = {
          productId: record.productId,
          quantity: record.extraQuantity,
          outletId: filter.outletId,
        };
        const response = await updateInventorryLedger(updatedPayload);
        if (response.data.meta.success) {
          setRefresh(!refresh);
          openNotification("success", "Updated Successfully");
        } else {
          openNotification("error", response.data.error);
        }
      } catch (err) {
        console.log(err);
        ServerError();
      }
    }
  };

  const renderTableRows = () =>
    allRecord.map((record, index) => (
      <tr key={index}>
        <td className="row-custom-table-body">
          <Tooltip
            title={
              <>
                <Row>Product Name: {record.name}</Row>
                <Row>SKU: {record.skuNo}</Row>
                <Row>Product Sku: {record.barcode}</Row>
                <Row>Re-Order Level: {record.reOrderLevel}</Row>
              </>
            }
          >
            <span>{record.name}</span>
          </Tooltip>
        </td>
        <td className="row-custom-table-body bg-[#f2fdff]">{record.onHand}</td>
        <td className="row-custom-table-body bg-[#fff8ed]">
          {record.commited}
        </td>
        <td className="row-custom-table-body bg-[#f6ffed]">
          {record.available}
        </td>
        <td className="row-custom-table-body">
          <Row className="flex items-center gap-2">
            <Col spam={8}>
              <Button
                onClick={() => onClickAdd(record)}
                disabled={filter.outletId?.length ? false : true}
                className="px-2 py-1 border rounded"
              >
                Add
              </Button>
            </Col>
            <Col spam={8}>
              <Input
                className=""
                placeholder="Extra Quantity"
                value={allRecord[index]?.extraQuantity}
                onChange={(e) =>
                  handleEdit(e.target.value, record, "extraQuantity")
                }
              />
            </Col>
            <Col spam={8}>
              <button
                disabled={filter.outletId?.length ? false : true}
                onClick={() => onClickSet(record)}
                className="px-2 py-1 bg-gray-200 rounded"
              >
                Set
              </button>
            </Col>
          </Row>
        </td>
        <td className="row-custom-table-body">
          <Tooltip
            className="text-center"
            overlayClassName="locationTooltip"
            title={
              <Row>
                <div className="relative w-full h-auto">
                  <h3 className="text-lg font-medium">Location/s</h3>
                  <div className="py-4 px-5 bg-white">
                    <table className="w-full">
                      <tbody>
                        {record.breakdown.map((data) => {
                          return (
                            <tr>
                              <td>{data?.outletName}</td>
                              <td className="bg-[#f2fdff]">
                                {data?.available}
                              </td>
                              <td className="bg-[#fff8ed]">{data?.commited}</td>
                              <td className="bg-[#f6ffed]">{data?.onHand}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Row>
            }
          >
            <p>{record.outletName}</p>
          </Tooltip>
        </td>
      </tr>
    ));

  return (
    <main className="mt-4 flex-1 flex flex-col overflow-hidden">
      <div className="p-6 bg-white rounded-3xl h-full flex flex-col">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-medium">My Inventory</h2>
          <div className="flex items-center gap-4">
            <Select
              className="w-52"
              options={inventoryType}
              onChange={(value) =>
                setFilter((prev) => ({ ...prev, inventoryType: value }))
              }
              placeholder="Select Inventory Type"
            />
            <Select
              className="w-52"
              options={productType}
              onChange={(value) =>
                setFilter((prev) => ({ ...prev, productType: value }))
              }
              placeholder="Select Product Type"
            />
            <Select
              className="w-52"
              options={dropDownPayload(outlet)}
              onChange={(value) =>
                setFilter((prev) => ({ ...prev, outletId: value }))
              }
              placeholder="Select Outlet"
            />
          </div>
        </div>
        <div className="flex flex-col justify-between overflow-auto scrollbar purchanseListing-table">
          <table className="w-full table-auto border-collapse">
            <thead>
              <tr className="custom-table-dic-header">
                <th className="px-4 py-2 font-medium">Inventory Item</th>
                <th className="px-4 py-2 font-medium">On Hand</th>
                <th className="px-4 py-2 font-medium">Commited</th>
                <th className="px-4 py-2 font-medium">Available</th>
                <th className="px-4 py-2 font-medium">
                  Edit Available Quantity
                </th>
                <th className="px-4 py-2 font-medium">Outlets</th>
              </tr>
            </thead>
            <tbody>{renderTableRows()}</tbody>
          </table>
        </div>
      </div>
    </main>
  );
}

export default InventoryAddSet;
