import { Form, Input } from "antd";
import React from "react";
import CustomSelect from "../../shared/customSelect/indes";
import { dropDownPayload } from "../../../utils/helper";

function Information({ outlet, customer }) {
  return (
    <>
      <div className="grid grid-cols-4 gap-5">
        <Form.Item
          name="transferDate"
          rules={[
            { required: true, message: "Please input the transfer date!" },
          ]}
        >
          <Input
            type="date"
            placeholder="Transfer Date"
            className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full mt-6 outline-0"
          />
        </Form.Item>
      </div>
      {/* SKU and Barcode Fields */}
      <div className="grid grid-cols-2 gap-5">
        <Form.Item
          name="toOutletId"
          rules={[{ required: true, message: "Please input the outlet!" }]}
        >
          <CustomSelect
            options={dropDownPayload(outlet)}
            placeholder={"Select Outlet"}
          />
          {/* unit */}
        </Form.Item>

        <Form.Item
          name="fromOutletId"
          rules={[{ required: true, message: "Please input the outlet!" }]}
        >
          <CustomSelect
            options={dropDownPayload(outlet)}
            placeholder={"Select Outlet"}
          />
          {/* unit */}
        </Form.Item>
      </div>

      <div className="">
        <Form.Item
          name="frieght"
          label={<span className="text-sm text-lightGrey">Frieght</span>}
          rules={[
            {
              required: true,
              message: "Please enter a frieght",
            },
          ]}
        >
          <Input
            type="number"
            className="border border-lightGrey rounded-2xl resize-none py-3 px-5"
            placeholder="Enter your frieght here"
          />
        </Form.Item>
      </div>
    </>
  );
}

export default Information;
