import PrivateLayout from "../../component/layout/privateLayout";
import Dashboard from "../../pages/dashboard";
import SettingPage from "../../pages/settingPage";
import Settings from "../../pages/settings";
import Products from "../../pages/products/product";
import AddProduct from "../../pages/products/product/addProduct";
import ProductType from "../../pages/products/product/productType";
import Brands from "../../pages/products/product/brands";
import Tags from "../../pages/products/product/tags";
import Seasons from "../../pages/products/product/seasons";
import Tier from "../../pages/customer/tiers";
import Tag from "../../pages/customer/tag";
import Customer from "../../pages/customer";
import Tax from "../../pages/products/product/tax";
import Unit from "../../pages/products/product/unit";
import Role from "../../pages/user/role";
import User from "../../pages/user";
import POS from "../../pages/pos/saleHistory";
import CashRegister from "../../pages/pos/cashRegister";
import ProcessSales from "../../pages/pos/processSales";
import Outlet from "../../pages/outlet";
import Store from "../../pages/outlet/store";
import Loyality from "../../pages/outlet/loyality";
import Register from "../../pages/outlet/register";
import Registerlisting from "../../pages/pos/cashRegister/listing";
import Purchase from "../../pages/purchase";
import AddPurchase from "../../pages/purchase/addPurchase";
import InventoryTransfer from "../../pages/inventory/inventoryTransfer";
import AddInventory from "../../pages/inventory/inventoryTransfer/addInventoryTransfer";
import Packages from "../../pages/inventory/inventoryTransfer/packages";
import Tenant from "../../pages/user/tenant";
import MyAccount from "../../pages/user/my-account";
import ProductOpeningBalance from "../../pages/inventory/opening-balance";
import InventoryAddSet from "../../pages/inventory/inventoryAddSet";

export const PrivateRoutes = [
  {
    path: "/",
    element: <PrivateLayout />,
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
      {
        path: "/settings",
        element: <Settings />,
      },
      {
        path: "/page",
        element: <SettingPage />,
      },
      {
        path: "/products",
        element: <Products />,
      },
      {
        path: "/products/add-product",
        element: <AddProduct />,
      },
      {
        path: "/products/product-types",
        element: <ProductType />,
      },
      {
        path: "/products/brands",
        element: <Brands type="brand" heading={"Brand"} />,
      },
      {
        path: "/products/tags",
        element: <Tags type="tag" heading={"Tags"} />,
      },
      {
        path: "/products/tax",
        element: <Tax type="tax" heading={"Tax"} />,
      },
      {
        path: "/products/unit",
        element: <Unit type="unit-type" heading={"Unit Type"} />,
      },
      {
        path: "/products/seasons",
        element: <Seasons type="season" heading={"Season"} />,
      },
      {
        path: "/customer",
        element: <Customer />,
      },
      {
        path: "/customer/tier",
        element: <Tier type="customer-tier" heading={"Tier"} />,
      },
      {
        path: "/customer/tag",
        element: <Tag type="customer-tag" heading={"Tag"} />,
      },
      {
        path: "/user/role",
        element: <Role />,
      },
      {
        path: "/user",
        element: <User />,
      },
      {
        path: "/pos/process-sales",
        element: <ProcessSales />,
      },
      {
        path: "/pos/sale-history",
        element: <POS />,
      },
      {
        path: "/pos/cash-register",
        element: <Registerlisting />,
      },
      {
        path: "/pos/cash-register-record",
        element: <CashRegister />,
      },
      {
        path: "/outlet",
        element: <Outlet />,
      },
      {
        path: "/store",
        element: <Store />,
      },
      {
        path: "/loyality-point",
        element: <Loyality />,
      },
      {
        path: "/cash-register-outlet",
        element: <Register />,
      },
      {
        path: "/purchase",
        element: <Purchase />,
      },
      {
        path: "/purchase/add-purchase",
        element: <AddPurchase />,
      },
      {
        path: "/invenroty/inventor-transfer",
        element: <InventoryTransfer />,
      },
      {
        path: "/invenroty/inventor-transfer/add",
        element: <AddInventory />,
      },
      {
        path: "/invenroty/packages",
        element: <Packages />,
      },
      {
        path: "/user/tenant",
        element: <Tenant />,
      },
      {
        path: "/user/my-account",
        element: <MyAccount />,
      },
      {
        path: "/products/opening-balance",
        element: <ProductOpeningBalance />,
      },
      {
        path: "/inventory-add-set",
        element: <InventoryAddSet />,
      },
    ],
  },
];
