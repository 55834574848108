import React, { useEffect, useState } from "react";
import {
  openNotification,
  ServerError,
} from "../../../component/shared/notification";
import { mySubscription } from "../../../services/user/tenant";

function MyAccount() {
  const [subscription, setSubscription] = useState({});

  const getSubscription = async () => {
    try {
      const { data: response } = await mySubscription();
      const { data: record } = response;
      setSubscription(record);
    } catch {
      return ServerError();
    }
  };

  useEffect(() => {
    getSubscription();
  }, []);

  return (
    <main class="flex-1 flex flex-col overflow-hidden">
      <div className="p-4 h-full flex flex-col">
        <div className="flex flex-col justify-between overflow-auto scrollbar">
          <h2 class="text-2xl font-medium flex-1 text-left mb-4">My Account</h2>
          <form class="grid gap-8 grid-cols-12 pb-10 xl:pb-24">
            <div class="col-span-12 xl:!col-span-9">
              <div class="bg-white rounded-3xl py-5 px-6">
                <h3 class="text-ufoGreen text-xl font-medium border-b pb-2.5 uppercase text-left">
                  Base Subscription Plan
                </h3>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Account created
                      </th>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Current plan
                      </th>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-left font-light text-sm py-2">
                        {new Date(
                          subscription?.businessInfo?.subscribedAt,
                        ).toLocaleDateString()}
                      </td>
                      <td class="text-left font-light text-sm py-2">
                        {subscription?.package?.name}
                      </td>
                      <td class="text-left font-light text-sm py-2">
                        {subscription?.businessInfo?.expiredAt < new Date() ? (
                          <>
                            <div className="mb-4">
                              Your free trial has ended.
                            </div>
                            <button
                              type="button"
                              className="leading-none block py-3 px-6 bg-ufoGreen rounded-full text-white font-medium border border-ufoGreen cursor-pointer uppercase"
                            >
                              Select Plan
                            </button>
                          </>
                        ) : (
                          <>
                            <div className="mb-4">
                              Trial active until:{" "}
                              {new Date(
                                subscription?.businessInfo?.expiredAt,
                              ).toLocaleDateString()}
                            </div>
                          </>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="bg-white rounded-3xl py-5 px-6 mt-6">
                <h3 class="text-ufoGreen text-xl font-medium border-b pb-2.5 uppercase text-left">
                  Account Owner
                </h3>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Owner info
                      </th>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Contact info
                      </th>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Email
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="text-left font-light text-sm py-2">
                        {subscription?.fullName}
                      </td>
                      <td class="text-left font-light text-sm py-2">
                        {subscription?.contactNo}
                      </td>
                      <td class="text-left font-light text-sm py-2">
                        {subscription?.email}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="bg-white rounded-3xl py-5 px-6 mt-6">
                <div class="flex items-center justify-between border-b pb-2.5">
                  <h3 class="text-ufoGreen text-xl font-medium uppercase">
                    Billing History
                  </h3>
                  <a
                    href="javascript:;"
                    class="text-ufoGreen text-l font-medium uppercase"
                  >
                    Edit
                  </a>
                </div>
                <table class="w-full">
                  <thead>
                    <tr>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Date
                      </th>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        BillingType
                      </th>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Billing period
                      </th>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Amount
                      </th>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Status
                      </th>
                      <th class="font-medium text-sm py-2 border-b border-lightBlue text-left">
                        Download
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {subscription?.billingHistory?.length <= 0 ? (
                      <tr>
                        <td
                          colSpan="6"
                          className="py-10 text-left border-b border-lightBlue"
                        >
                          <span className="block text-lightGrey text-xl text-center opacity-70">
                            No Records Found
                          </span>
                        </td>
                      </tr>
                    ) : (
                      subscription?.billingHistory?.map((bh, index) => (
                        <tr key={index}>
                          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
                            {new Date(bh.date).toLocaleDateString()}
                          </th>
                          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
                            {bh.billingType}
                          </th>
                          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
                            {bh.billingPeriod}
                          </th>
                          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
                            {bh.amount}
                          </th>
                          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
                            {bh.status}
                          </th>
                          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
                            Download
                          </th>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div class="col-span-12 xl:!col-span-3">
              <div class="bg-white rounded-3xl py-5 px-6">
                <h3 class="text-ufoGreen text-xl font-medium uppercase border-b pb-2.5 text-left">
                  Billing information
                </h3>
                <p class="font-light text-sm mt-4 mb-4 text-left">
                  Your credit card information for Hike subscription payment.
                </p>
                <div class="flex items-center justify-start">
                  <button
                    type="submit"
                    value=""
                    class="block capitalize py-4 px-10 rounded-full leading-none text-bold bg-redOrange text-white"
                  >
                    Add Credit Card
                  </button>
                </div>
              </div>
              <div class="bg-white rounded-3xl py-5 px-6 mt-6">
                <h3 class="text-ufoGreen text-xl font-medium uppercase border-b pb-2.5 text-left">
                  Data Purge
                </h3>
                <p class="font-light text-sm mt-4 mb-4 text-left">
                  Use this to permanently delete products and sales
                  transactions. Very useful in clearing dummy products and test
                  transactions created during trial. Not advised for real
                  records.
                </p>
                <div class="flex items-center justify-start">
                  <button
                    type="submit"
                    class="leading-none block py-4 px-10 bg-veryLightGrey border border-lightGrey text-lightGrey block capitalize py-4 px-8 rounded-full leading-none text-bold"
                  >
                    Purge Data
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}

export default MyAccount;
