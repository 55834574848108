import Api from "../../network/client";

export const createPackage = async (data) => {
  return Api.post(`package`, data);
};
export const updatePackage = async (data, id) => {
  return Api.put(`package/${id}`, data);
};
export const getAllPackageApi = async () => {
  return Api.get(`package`);
};
export const deletePackage = async (id) => {
  return Api.delete(`package/${id}`);
};
