import Api from "../../network/client";

export const createSales = async (data) => {
  return Api.post(`sales`, data);
};
export const updatedSales = async (data, id) => {
  return Api.put(`sales/${id}`, data);
};

export const getSalesById = async (id) => {
  return Api.get(`sales/${id}`);
};
