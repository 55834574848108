import React, { useEffect, useState } from "react";
import BasicCard from "../../component/shared/basicCard/basicCard";
import { Form } from "antd";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import { useLocation, useNavigate } from "react-router-dom";
import { getAllOutletApi } from "../../services/outlet";
import { getAllCustomerApi } from "../../services/customer";
import Information from "../../component/purchase/information";
import ComponentProduct from "../../component/purchase/productForm";
import {
  createPurchase,
  updatePurchase,
  getPurchaseById,
} from "../../services/purchase";

const variants = [
  {
    combination: [],
    sku: "",
    barcode: "",
    costPrice: 0,
    retailPrice: 0,
    retailPriceInclTax: 0,
    tax: 0,
    taxId: "",
    discount: 0,
    discountTypeId: null,
  },
];

function AddPurchase() {
  const [form] = Form.useForm();
  const location = useLocation();
  const navigate = useNavigate();
  const [id, setId] = useState(location.state?.id ? location?.state?.id : null);
  const [outlet, setOutlet] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState("CASH");

  const getPurchaseDetails = async (id) => {
    try {
      const {
        data: { data },
      } = await getPurchaseById(id);
      if (data) {
        const variantIds = data.purchaseDetails.map((val) => val._id);
        form.setFieldValue("compositeProductsIds", variantIds);
        const updatedData = data.purchaseDetails.map((data) => {
          return {
            ...data,
            costPrice: data.price,
          };
        });
        form.setFieldValue("compositeProducts", updatedData);
        form.setFieldsValue(data);

        console.log(updatedData, "asdsadsad");
        const compositeProduct = Array.isArray(updatedData) ? updatedData : [];
        const total = compositeProduct.reduce((acc, product) => {
          const price = Number(product?.total || 0);
          return acc + price;
        }, 0);
        form.setFieldValue("total", Number(total));
      }
    } catch (err) {
      console.log({ err });
      ServerError();
    }
  };

  useEffect(() => {
    if (id) {
      getPurchaseDetails(id);
    } else {
      form.setFieldsValue({ variants: variants });
    }
  }, []);

  const getListOfValues = async () => {
    try {
      const [getAllOutletRes, customerRes] = await Promise.all([
        getAllOutletApi(),
        getAllCustomerApi(),
      ]);
      setOutlet(getAllOutletRes.data.data.data);
      setCustomer(customerRes.data.data.data);
    } catch (err) {
      console.error("Error fetching data", { err });
    }
  };

  useEffect(() => {
    getListOfValues();
  }, []);

  const onFinish = async (values) => {
    let response;
    let payload;
    values.compositeProducts.forEach((product) => {
      product.price = product.retailPriceInclTax;
    });
    try {
      payload = {
        ...values,
        isActive: true,
        purchaseDetails: values.compositeProducts,
        paymentStatus: "PARKED",
        paymentType: selectedProduct,
      };
      delete payload.compositeProducts;
      delete payload.compositeProductsIds;
      if (id) {
        response = await updatePurchase(payload, id);
      } else {
        response = await createPurchase(payload);
      }
      if (response.data.meta.success) {
        form.resetFields();
        openNotification("success", "Product added successfully");
        navigate("../purchase");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log({ err });
      ServerError();
    }
  };

  return (
    <main className="mt-6 text-left flex-1 overflow-y-scroll scrollbar text-left">
      <Form
        form={form}
        name="descriptionForm"
        layout="vertical"
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="grid gap-8 grid-cols-12">
          <div className="col-span-12">
            <BasicCard heading={"Add Purchase"} className="addProductInfo">
              <Information
                selectedProduct={selectedProduct}
                setSelectedProduct={setSelectedProduct}
                outlet={outlet}
                customer={customer}
              />
            </BasicCard>

            <BasicCard heading={"Summary"}>
              <ComponentProduct form={form} />
            </BasicCard>
          </div>
          <div className="col-span-12">
            <div className="flex items-center justify-end gap-3">
              <Form.Item>
                <input
                  type="button"
                  onClick={() => navigate("../purchase")}
                  defaultValue="Cancel"
                  className="leading-none block py-4 px-10 bg-veryLightGrey rounded-full text-lightGrey font-medium border border-lightGrey"
                />
              </Form.Item>
              <Form.Item>
                <input
                  type="submit"
                  defaultValue="Save"
                  className="leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium border border-redOrange"
                />
              </Form.Item>
            </div>
          </div>
        </div>
      </Form>
    </main>
  );
}

export default AddPurchase;
