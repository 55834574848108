import React, { useEffect, useState } from "react";
import { Form, Switch } from "antd";
import {
  getAllLanguages,
  getAllListOfValueByKey,
} from "../../../services/globalService";
import { dropDownPayload, dropDownPayloadLov } from "../../../utils/helper";
import CustomSelect from "../../shared/customSelect/indes";

function AddPackage({ onFinish, form }) {
  const [language, setLanguage] = useState([]);
  const [packageMode, setPackageMode] = useState([]);

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("package_mode");
      setPackageMode(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const getLanguage = async () => {
    try {
      const { data: response } = await getAllLanguages();
      const { data: record } = response;
      setLanguage(record.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getLanguage();
    getListOfValues();
  }, []);
  return (
    <Form onFinish={onFinish} form={form}>
      <div className="grid grid-cols-12 h-[600px] w-[900px] text-left">
        <div className="col-span-12">
          <div class="flex items-center justify-start mt-6 gap-5 pr-5">
            {/* First Name */}
            <Form.Item
              name="name"
              rules={[{ required: true, message: "Please enter name" }]}
              className="flex-1"
            >
              <input
                type="text"
                placeholder="Name"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
            <Form.Item
              name="packageModeId"
              className="flex-1"
              rules={[{ required: true, message: "Please select an option" }]}
            >
              <CustomSelect
                options={dropDownPayloadLov(packageMode)}
                placeholder={"Select package mode"}
              />
            </Form.Item>
          </div>

          <div class="flex items-center justify-start mt-6 gap-5 pr-5">
            <Form.Item
              name="languageId"
              className="flex-1"
              rules={[{ required: true, message: "Please select an option" }]}
            >
              <CustomSelect
                options={dropDownPayload(language)}
                placeholder={"Select Language"}
              />
            </Form.Item>
            <Form.Item
              name="isTrialMode"
              label="Trial Mode"
              rules={[{ required: false, message: "Please select an option" }]}
            >
              <Switch />
            </Form.Item>
          </div>
          <div class="flex items-center justify-start gap-5 pr-5">
            <Form.Item
              name="invoicesAllow"
              rules={[
                { required: true, message: "Please enter invoices allow" },
              ]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Invoices Allow"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>

            <Form.Item
              name="customersAllow"
              rules={[
                { required: true, message: "Please enter customers allow" },
              ]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Customers Allow"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
          </div>

          <div class="flex items-center justify-start gap-5 pr-5">
            <Form.Item
              name="purchasesAllow"
              rules={[
                { required: true, message: "Please enter purchases allow" },
              ]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Purchases Allow"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
            <Form.Item
              name="productsAllow"
              rules={[
                { required: true, message: "Please enter products allow" },
              ]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Products Allow"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
          </div>
          <div class="flex items-center justify-start gap-5 pr-5">
            <Form.Item
              name="inventoryTransferAllow"
              rules={[
                {
                  required: true,
                  message: "Please enter inventory transfer allow",
                },
              ]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Inventory Transfer Allow"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>

            <Form.Item
              name="outletsAllow"
              rules={[
                { required: true, message: "Please enter outlets allow" },
              ]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Outlets Allow"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
          </div>

          <div class="flex items-center justify-start gap-5 pr-5">
            <Form.Item
              name="cashRegisterAllow"
              rules={[
                { required: true, message: "Please enter Cash Register allow" },
              ]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Cash Register Allow"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>

            <Form.Item
              name="usersAllow"
              rules={[
                {
                  required: true,
                  message: "Please enter inventory users allow",
                },
              ]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Users Allow"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
          </div>

          <div class="flex items-center justify-start gap-5 pr-5">
            <Form.Item
              name="rate"
              rules={[{ required: true, message: "Please enter rate" }]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Rate"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>

            <Form.Item
              name="tax"
              rules={[{ required: true, message: "Please enter tax" }]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Tax"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>

            <Form.Item
              name="total"
              rules={[{ required: true, message: "Please enter total" }]}
              className="flex-1"
            >
              <input
                type="number"
                placeholder="Enter Total"
                className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
              />
            </Form.Item>
          </div>
        </div>

        <div className="col-span-12 flex items-center justify-start gap-3 mt-2 mb-4">
          <button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 bg-redOrange rounded-full text-white font-medium"
          >
            Save
          </button>
        </div>
        <br></br>
      </div>
    </Form>
  );
}

export default AddPackage;
