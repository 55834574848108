import React from "react";
import { safeNumber } from "../../../utils/helper";

function PaymentSymmary2({ registerData }) {
  const { transaction } = registerData || {};

  const calculate = (rec, ref) => {
    return Number(Number(rec) - Number(ref)).toFixed(2);
  };
  return (
    <table className="w-full">
      <thead>
        <tr>
          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
            Payment Recieved
          </th>
          <th className="font-medium text-sm py-2 border-b border-lightBlue text-left">
            Refunds
          </th>
          <th className="font-medium text-sm py-2 border-b border-lightBlue text-right">
            Net Receipts
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
            {safeNumber(transaction?.received)}
          </td>
          <td className="text-left font-light text-sm py-2 border-b border-lightBlue">
            {safeNumber(transaction?.refund)}
          </td>
          <td className="text-right font-light text-sm py-2 border-b border-lightBlue">
            {calculate(transaction?.received || 0, transaction?.refund || 0)}
          </td>
        </tr>
      </tbody>
    </table>
  );
}

export default PaymentSymmary2;
