import React, { useEffect, useState } from "react";
import CustomModal from "../../../../component/shared/modal";
import { Form, Image, message } from "antd";
import {
  getAllListOfValueByKey,
  getSlugListOfValuesByKey,
} from "../../../../services/globalService";
import {
  openNotification,
  ServerError,
} from "../../../../component/shared/notification";
import CustomTable from "../../../../component/shared/customTable";
import {
  createPackage,
  deletePackage,
  updatePackage,
} from "../../../../services/packages";
import AddPackage from "../../../../component/inventory/package/addPackage";

function Packages() {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gender, setGender] = useState([]);
  const [tier, setTier] = useState([]);
  const [tag, setTag] = useState([]);
  const [id, setId] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);

  const [form] = Form.useForm();

  const fetchListOfValues = async () => {
    try {
      const { data: response } =
        await getSlugListOfValuesByKey("customer-tier");
      setTier(response.data.data);
      const { data: tagData } = await getSlugListOfValuesByKey("customer-tag");
      setTag(tagData.data.data);
    } catch {
      return ServerError();
    }
  };

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("gender");
      setGender(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
  }, []);

  useEffect(() => {
    fetchListOfValues();
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const onClickHandle = (row) => {
    setId(row._id);
    form.setFieldsValue(row);
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Package Mode",
      // dataIndex: "customer",
      // key: "customer",
      render: (_, row) => {
        return row?.packageMode?.value;
      },
    },
    {
      title: "Language",
      // dataIndex: "customer",
      // key: "customer",
      render: (_, row) => {
        return row?.language?.name;
      },
    },
    {
      title: "Trial Mode",
      // dataIndex: "customer",
      // key: "customer",
      render: (_, row) => {
        return row?.isTrialMode ? "Yes" : "No";
      },
    },
    {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
    },
    {
      title: "Tax",
      dataIndex: "tax",
      key: "tax",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
    },
    {
      title: "Invoices Allow",
      dataIndex: "invoicesAllow",
      key: "invoicesAllow",
    },
    {
      title: "Customers Allow",
      dataIndex: "customersAllow",
      key: "customersAllow",
    },
    {
      title: "Purchases Allow",
      dataIndex: "purchasesAllow",
      key: "purchasesAllow",
    },
    {
      title: "Inventory Transfer Allow",
      dataIndex: "inventoryTransferAllow",
      key: "inventoryTransferAllow",
    },

    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, row) => {
        return row?.createdBy?.fullName;
      },
    },
    {
      title: "Modified By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (_, row) => {
        return row?.updatedBy?.fullName;
      },
    },
  ];

  const onClickDelete = async (row) => {
    try {
      const { data } = await deletePackage(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    let response;

    try {
      const payload = {
        ...values,
        isActive: true,
      };
      if (id) {
        response = await updatePackage(payload, id);
      } else {
        response = await createPackage(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Successfully added");
      } else {
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  const onClickAdd = () => {
    openModal();
    form.resetFields();
  };
  const onClickStatus = async (row) => {
    const payload = {
      ...row,
      isActive: !row.isActive,
    };
    try {
      const response = await updatePackage(payload, row._id);
      if (
        response.data.meta.status == 201 ||
        response.data.meta.status == 200
      ) {
        message.success(`Successfully Updated`);
        setRefreshTable((prev) => !prev);
      }
    } catch (err) {
      message.success(`Error occured`);
      console.log(err);
    }
  };
  return (
    <main className="mt-6 flex-1 flex flex-col overflow-hidden">
      <div className="p-6 bg-white rounded-3xl h-full flex flex-col">
        <div className="flex items-center justify-between mb-8 text-left">
          <h2 className="text-2xl font-medium">Packages</h2>
          <div className="flex items-center justify-end gap-6">
            <a
              onClick={onClickAdd}
              className="block capitalize py-4 px-6 min-w-36 text-center rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add Packages
            </a>

            <CustomModal
              isModalOpen={isModalOpen}
              closeModal={closeModal}
              heading={`Add Packages`}
            >
              <AddPackage
                onFinish={onFinish}
                form={form}
                tier={tier}
                tag={tag}
                gender={gender}
              />
            </CustomModal>
          </div>
        </div>
        <form action="" className="relative mb-6">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Find Packages"
            className="block leading-none py-3 pr-10 pl-7 border w-full rounded-full"
          />
          <div className="overlay hidden fixed top-0 bottom-0 left-0 w-full h-100 bg-black z-10 opacity-35" />
        </form>
        {/* Records */}
        <div className="flex flex-col justify-between overflow-auto scrollbar packagesDeatils-table">
          <CustomTable
            columns={columns}
            endPoint={"package"}
            search={searchText}
            handleEdit={onClickHandle}
            handleDelete={onClickDelete}
            // handleStatus={onClickStatus}
            showActions={true}
            refreshTable={refreshTable}
          />
        </div>
      </div>
    </main>
  );
}

export default Packages;
