import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { getAllTaxApi } from "../../../services/product/tax";
import CustomSelect from "../../shared/customSelect/indes";
import { dropDownPayloadLov, formatNames } from "../../../utils/helper";
import { getAllListOfValueByKey } from "../../../services/globalService";

function Pricing({ form, selectedProduct }) {
  const [tax, setTax] = useState([]);
  const [discountType, setDiscountType] = useState([]);
  const { Option } = Select;
  const getAllTax = async () => {
    try {
      const response = await getAllTaxApi("tax");
      setTax(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllTax();
  }, []);

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("discount_type");
      setDiscountType(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
  }, []);

  const getTaxRate = (taxId) => {
    const tempTax = tax.find((data) => data._id == taxId);
    return tempTax?.rate || 0;
  };
  const OnchangeDiscountType = (value, name) => {
    const values = form.getFieldsValue();
    const variant = values.variants[name];
    const discountTypeId = variant.discountTypeId;
    // Update the form with the calculated retail price including tax
    const disCountTypeKey = discountType.find((data) => data._id == value);
    form.setFieldsValue({
      variants: {
        ...values.variants,
        [name]: {
          ...variant,
          discountTypeId: value,
          disCountTypeKey: disCountTypeKey.key,
        },
      },
    });
    handlePriceChange(name);
  };
  const handlePriceChange = (name, onChangeRetailPrice) => {
    const values = form.getFieldsValue();
    const variant = values.variants[name];
    const costPrice = parseFloat(variant.costPrice) || 0;
    const retailPriceForm = parseFloat(variant.retailPrice) || 0;
    const discount = parseFloat(variant.discount) || 0;
    const taxId = variant.taxId;
    const taxRate = getTaxRate(taxId); // Get the tax rate in percentage
    const disCountTypeKey = discountType.find(
      (data) => data._id == variant.discountTypeId,
    ).key;
    if (onChangeRetailPrice == "retailPrice") {
      if (disCountTypeKey == "percentage") {
        const markup = ((retailPriceForm - costPrice) / costPrice) * 100;
        form.setFieldsValue({
          variants: {
            ...values.variants,
            [name]: {
              ...variant,
              discount: markup.toFixed(2),
            },
          },
        });
        return;
      } else {
        const markup = retailPriceForm - costPrice;
        form.setFieldsValue({
          variants: {
            ...values.variants,
            [name]: {
              ...variant,
              discount: markup.toFixed(2),
            },
          },
        });
      }
    } else {
      const taxAmount = (costPrice * taxRate) / 100;
      const discountAmount =
        disCountTypeKey == "percentage"
          ? (costPrice * discount) / 100
          : discount;

      let retailPriceInclTax, retailPrice;
      retailPrice = costPrice + discountAmount;
      retailPriceInclTax = costPrice + taxAmount + discountAmount;

      form.setFieldsValue({
        variants: {
          ...values.variants,
          [name]: {
            ...variant,
            retailPriceInclTax: retailPriceInclTax.toFixed(2),
            retailPrice: retailPrice.toFixed(2),
            tax: taxRate,
          },
        },
      });
    }
  };

  const handleCopy = () => {
    const values = form.getFieldsValue();
    const variants = values.variants || [];

    if (variants.length === 0) return;

    const firstVariant = variants[0];

    const updatedVariants = variants.map((variant, index) => ({
      ...variant,
      ...firstVariant,
      combination: variant.combination, // Keep unique combination
    }));

    form.setFieldsValue({ variants: updatedVariants });
  };
  return (
    <div className="addProductPricing flex flex-col gap-6 mt-6">
      <Form.List name="variants">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }, index) => (
              <>
                {index == 0 && selectedProduct == "PRODUCT_WITH_VARIANT" && (
                  <div className="row flex justify-end">
                    <Button
                      onClick={() => handleCopy(index)}
                      color="default"
                      variant="solid"
                    >
                      Same as above
                    </Button>
                  </div>
                )}
                <div className="row">
                  <div className="flex gap-4 items-start">
                    {formatNames(
                      form.getFieldsValue()?.variants &&
                        form.getFieldsValue()?.variants[name]?.combination,
                    )}
                  </div>
                  <div
                    className="grid grid-cols-4 xl:!grid-cols-5 gap-4"
                    key={key}
                  >
                    <Form.Item
                      {...restField}
                      name={[name, "costPrice"]}
                      fieldKey={[fieldKey, "costPrice"]}
                      label="Cost Price"
                      rules={[
                        { required: true, message: "Please enter cost price" },
                      ]}
                      className="flex-1"
                    >
                      <Input
                        onChange={(e) => handlePriceChange(name)}
                        type="number"
                        placeholder="Rs.0.00"
                        className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                      />
                    </Form.Item>

                    {/* Discount */}
                    <Form.Item
                      {...restField}
                      name={[name, "discount"]}
                      fieldKey={[fieldKey, "discount"]}
                      label="Markup"
                      className="flex-1"
                      rules={[
                        { required: true, message: "Please enter discount" },
                      ]}
                    >
                      <Input
                        onChange={(e) => handlePriceChange(name)}
                        type="number"
                        defaultValue={"0.0"}
                        className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                      />
                    </Form.Item>

                    {/* Discount Type */}
                    <Form.Item
                      {...restField}
                      name={[name, "discountTypeId"]}
                      fieldKey={[fieldKey, "taxId"]}
                      label="Markup Type"
                      className="h-16 flex-1 mt-2"
                    >
                      <CustomSelect
                        onChange={(value) => OnchangeDiscountType(value, name)}
                        options={dropDownPayloadLov(discountType)}
                      />
                    </Form.Item>

                    {/* Retail Price */}
                    <Form.Item
                      {...restField}
                      name={[name, "retailPrice"]}
                      fieldKey={[fieldKey, "retailPrice"]}
                      label="Retail Price (Ex. Tax)"
                      rules={[
                        {
                          required: true,
                          message: "Please enter retail price",
                        },
                      ]}
                      className="flex-1"
                    >
                      <Input
                        onChange={(e) => handlePriceChange(name, "retailPrice")}
                        // disabled
                        placeholder="Rs.0.00"
                        type="number"
                        className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                      />
                    </Form.Item>

                    <Form.Item
                      {...restField}
                      name={[name, "taxId"]}
                      fieldKey={[fieldKey, "taxId"]}
                      label="Tax Rate"
                      className="flex-1"
                    >
                      <Select
                        placeholder="Tax"
                        className="h-auto mt-2"
                        onChange={(value) => handlePriceChange(name)}
                      >
                        {tax.map((data, key) => {
                          return (
                            <Option key={key} value={data._id}>
                              {data.name} {data.rate}%
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>

                    {/* Retail Price Incl. Tax */}
                    <Form.Item
                      {...restField}
                      name={[name, "retailPriceInclTax"]}
                      fieldKey={[fieldKey, "retailPriceInclTax"]}
                      label="Retail Price (Inc. Tax)"
                      rules={[
                        {
                          required: true,
                          message: "Please enter retail price (Inc. Tax)",
                        },
                      ]}
                      className="flex-1"
                    >
                      <Input
                        onChange={(e) => handlePriceChange(name)}
                        placeholder="Rs.0.00"
                        type="number"
                        disabled
                        className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                      />
                    </Form.Item>
                  </div>
                </div>
              </>
            ))}
          </>
        )}
      </Form.List>
    </div>
  );
}

export default Pricing;
