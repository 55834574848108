import { Form, Input, Select, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getAllTaxApi } from "../../services/product/tax";
import CustomSelect from "../shared/customSelect/indes";
import { dropDownPayloadLov } from "../../utils/helper";
import { getAllListOfValueByKey } from "../../services/globalService";

function PurchasePricing({ form }) {
  const [tax, setTax] = useState([]);
  const [discountType, setDiscountType] = useState([]);
  const [retailPrice, setRetailPrice] = useState("");
  const [totalPrice, setTotalPrice] = useState("");

  const { Option } = Select;
  const getAllTax = async () => {
    try {
      const response = await getAllTaxApi("tax");
      setTax(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getAllTax();
  }, []);

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("discount_type");
      setDiscountType(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getListOfValues();
    calculateTotalPriceEx();
  }, []);

  const onChangeDiscountType = (value, name) => {
    const values = form.getFieldsValue();
    const compositeProduct = values.compositeProducts[name];
    const discountTypeId = compositeProduct.discountTypeId;
    // Update the form with the calculated retail price including tax
    const discountTypeKey = discountType.find((data) => data._id == value).key;
    form.setFieldsValue({
      compositeProducts: {
        ...values.compositeProducts,
        [name]: {
          ...compositeProduct,
          discountTypeId: value,
          discountTypeKey: discountTypeKey,
          quantity: 1,
        },
      },
    });
    handlePriceChange(name);
  };
  const handlePriceChange = (name) => {
    const values = form.getFieldsValue();
    const compositeProduct = values.compositeProducts[name];
    const quantity = Number(compositeProduct.quantity) || 0;
    let total;
    if (compositeProduct.discountTypeKey === "fix_rate") {
      total =
        Number(compositeProduct.costPrice) * Number(compositeProduct.quantity) -
        Number(compositeProduct.discount);
    } else if (compositeProduct.discountTypeKey === "percentage") {
      total =
        Number(compositeProduct.costPrice) * Number(compositeProduct.quantity) -
        Number(compositeProduct.costPrice) *
          Number(compositeProduct.quantity) *
          (Number(compositeProduct.discount) / 100);
    }
    const taxPercentage = Number(values.taxId) || 0;
    const taxAmount = (total * taxPercentage) / 100;
    total += taxAmount;

    const taxId = values.compositeProducts[name].taxId;
    const taxValue = tax.find((item) => item._id === taxId);
    const taxDecimal = Number(taxValue.rate) / 100; // Convert to decimal
    const taxableAmount =
      Number(compositeProduct.costPrice) *
      taxDecimal *
      Number(compositeProduct.quantity);
    total = taxableAmount + total;

    form.setFieldsValue({
      compositeProducts: {
        [name]: {
          ...compositeProduct,
          quantity: Number(quantity),
          total,
        },
      },
    });
    calculateTotalPriceEx();
  };

  useEffect(() => {
    calculateTotalPriceEx();
  }, [form]);

  const calculateTotalPriceEx = () => {
    const values = form?.getFieldsValue() || {};
    const compositeProduct = Array.isArray(values.compositeProducts)
      ? values.compositeProducts
      : [];
    const total = compositeProduct.reduce((acc, product) => {
      const price = Number(product?.total || 0);
      return acc + price;
    }, 0);
    form.setFieldValue("total", Number(total));
    setRetailPrice(Number(total));
  };

  return (
    <div className="flex flex-col gap-6 mt-6">
      <Form.List name="compositeProducts">
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, fieldKey, ...restField }) => (
              <div className="flex gap-4 items-start" key={key}>
                <Form.Item
                  {...restField}
                  name={[name, "costPrice"]}
                  fieldKey={[fieldKey, "costPrice"]}
                  label="Cost Price"
                  rules={[
                    { required: true, message: "Please enter cost price" },
                  ]}
                  className="flex-1"
                >
                  <Input
                    onChange={(e) => handlePriceChange(name)}
                    type="number"
                    placeholder="Rs.0.00"
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>
                {/* <Form.Item
                  {...restField}
                  name={[name, "retailPriceInclTax"]}
                  fieldKey={[fieldKey, "retailPriceInclTax"]}
                  label="Retail Price Incl Tax"
                  rules={[
                    { required: true, message: "Please enter cost price" },
                  ]}
                  className="flex-1"
                >
                  <Input
                    onChange={(e) => handlePriceChange(name)}
                    type="number"
                    placeholder="Rs.0.00"
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item> */}

                {/* Discount */}
                <Form.Item
                  {...restField}
                  name={[name, "discount"]}
                  fieldKey={[fieldKey, "discount"]}
                  label="Discount"
                  className="flex-1"
                >
                  <Input
                    // disabled
                    onChange={(e) => handlePriceChange(name)}
                    type="number"
                    defaultValue={"0.0"}
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "discountTypeId"]}
                  fieldKey={[fieldKey, "taxId"]}
                  label="Discount Type"
                  className="flex-1"
                  disabled
                >
                  <CustomSelect
                    // disabled
                    onChange={(value) => onChangeDiscountType(value, name)}
                    options={dropDownPayloadLov(discountType)}
                  />
                </Form.Item>

                {/* <Form.Item
                  {...restField}
                  name={[name, "retailPrice"]}
                  fieldKey={[fieldKey, "retailPrice"]}
                  label="Retail Price (Ex. Tax)"
                  disabled
                  rules={[
                    { required: true, message: "Please enter retail price" },
                  ]}
                  className="flex-1"
                >
                  <Input
                    onChange={(e) => handlePriceChange(name)}
                    disabled
                    placeholder="Rs.0.00"
                    type="number"
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item> */}

                <Form.Item
                  {...restField}
                  name={[name, "taxId"]}
                  fieldKey={[fieldKey, "taxId"]}
                  label="Tax Rate"
                  className="flex-1"
                >
                  <Select
                    placeholder="Tax"
                    className="h-auto"
                    onChange={(value) => handlePriceChange(name)}
                  >
                    {tax.map((data, key) => {
                      return (
                        <Option key={key} value={data._id}>
                          {data.name} {data.rate}%
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "quantity"]}
                  fieldKey={[fieldKey, "quantity"]}
                  label="Quantity"
                  rules={[
                    {
                      required: true,
                      message: "Please enter quantity",
                    },
                  ]}
                  className="flex-1"
                >
                  <Input
                    onChange={(e) => handlePriceChange(name)}
                    placeholder="0.00"
                    type="number"
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>

                <Form.Item
                  {...restField}
                  name={[name, "total"]}
                  fieldKey={[fieldKey, "total"]}
                  label="Total"
                  rules={[
                    {
                      required: true,
                      message: "Please enter Total",
                    },
                  ]}
                  className="flex-1"
                >
                  <Input
                    disabled
                    onChange={(e) => handlePriceChange(name)}
                    placeholder="0.00"
                    type="number"
                    className="block leading-none py-3 px-4 border border-lightGrey w-full rounded-full mt-2 outline-0"
                  />
                </Form.Item>
              </div>
            ))}
          </>
        )}
      </Form.List>

      <Form.Item
        name="total"
        rules={[{ required: true, message: "Please input the Price!" }]}
        label="Sub Total"
        className="flex-1 mb-0"
      >
        <Input
          disabled
          placeholder="RS 0.0"
          className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
        />
      </Form.Item>

      <Form.Item
        name="surcharge"
        // rules={[{ required: true, message: "Please input the Surcharge!" }]}
        label="Surcharge"
        className="flex-1 mb-0"
      >
        <Input
          placeholder="RS 0.0"
          className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
        />
      </Form.Item>

      <Form.Item
        name=""
        // rules={[{ required: true, message: "Please input the Paid!" }]}
        label="Grand Total"
        className="flex-1 mb-0"
      >
        <Input
          disabled
          placeholder="RS 0.0"
          className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
        />
      </Form.Item>

      <Form.Item
        name="paid"
        // rules={[{ required: true, message: "Please input the Paid!" }]}
        label="Paid"
        className="flex-1 mb-0"
      >
        <Input
          placeholder="RS 0.0"
          className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
        />
      </Form.Item>
    </div>
  );
}

export default PurchasePricing;
