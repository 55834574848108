import React, { useEffect, useState } from "react";
import CustomModal from "../../component/shared/modal";
import SearchInput from "../../component/shared/searchInput";
// import AddOutlet from "../../component/products/addOutlet";
import CustomTable from "../../component/shared/customTable";
import {
  changeOutletStatus,
  createOutlet,
  deleteOutletApi,
  getAllOutletApi,
  updateOutlet,
} from "../../services/outlet/index";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import { Form, Image } from "antd";
import AddOutlet from "../../component/outlet/addOutlet";
import { getAllListOfValueByKey } from "../../services/globalService";
import { addDayIdToSchedule } from "../../utils/helper";

function Outlet() {
  const [searchText, setSearchText] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState(null);
  const [uploadedUrl, setUploadedUrl] = useState(null);
  const [refreshTable, setRefreshTable] = useState(false);
  const [data, setData] = useState([]);
  const [weekDays, setweekDays] = useState([]);
  const [form] = Form.useForm();

  const getListOfValues = async () => {
    try {
      const response = await getAllListOfValueByKey("week_day");
      setweekDays(response.data.data.data);
    } catch (err) {
      console.log(err);
    }
  };

  function replaceDayIdWithId(data) {
    return data.map((item) => {
      // Rename dayId to _id
      item.dayId = item._id;
      delete item._id;
      return item;
    });
  }

  function replaceWithDayId(data) {
    return data.map((item) => {
      item.dayId = item._id;
      item.isOpen = item.isOpen == true ? true : false;
      delete item._id;
      return item;
    });
  }

  const onClickStatus = async (row) => {
    try {
      const payload = {
        ...row,
        isActive: !row.isActive,
        parentId: null,
      };
      const { data } = await changeOutletStatus(row._id, payload);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Status updated successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };
  const onClickDelete = async (row) => {
    try {
      const { data } = await deleteOutletApi(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onFinish = async (values) => {
    const a = replaceDayIdWithId(values.openingHours);
    // const updatedSchedule = addDayIdToSchedule(values.openingHours, weekDays);
    let response;
    try {
      const payload = {
        ...values,
        isActive: true,
        openingHours: a,
      };
      if (id) {
        response = await updateOutlet(payload, id);
      } else {
        response = await createOutlet(payload);
      }
      if (response.data.meta.success) {
        setIsModalOpen(false);
        form.resetFields();
        setRefreshTable((prev) => !prev);
        openNotification("success", "Outlet successfully saved");
        setweekDays([]);
      } else {
        setweekDays([]);
        openNotification("error", response.data.error);
      }
    } catch (err) {
      console.log(err);
      ServerError();
    }
  };
  const onClickEdit = async (row) => {
    const result = {};
    setId(row._id);
    const transformData = replaceWithDayId(row.openingHours);
    setweekDays(transformData);
    const tempData = transformData;
    tempData.forEach((item) => {
      const day = item.key;
      result[day] = {
        isOpen: item.isOpen == true ? true : false,
        startTime: item.startTime ? item.startTime : "09:00",
        endTime: item.endTime ? item.endTime : "17:00",
      };
    });
    form.setFieldsValue({ ...row, ...tempData });
    setUploadedUrl(row.image);
    setIsModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    form.resetFields();
    setIsModalOpen(false);
    setweekDays([]);
  };
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },
  ];

  const onClickAdd = async () => {
    getListOfValues();
    openModal();
    setId(null);
    form.resetFields();
    try {
      const response = await getAllListOfValueByKey("week_day");
      form.setFieldsValue({
        openingHours: response.data.data.data.map((d) => {
          return {
            ...d,
            startTime: "09:00",
            endTime: "17:00",
          };
        }),
        purchaseOrderConfig: {
          type: 1,
          prefix: "SR",
          suffix: ".",
        },
        inventoryTransferConfig: {
          type: 1,
          prefix: "SR",
          suffix: ".",
        },
        inventoryCountConfig: {
          type: 1,
          prefix: "SR",
          suffix: ".",
        },
      });
    } catch (err) {
      console.log(err);
    }
    setUploadedUrl(null);
  };

  return (
    <main className="mt-6 flex-1 flex flex-col overflow-hidden">
      <div className="p-6 bg-white rounded-3xl h-full flex flex-col">
        <div className="flex items-center justify-between mb-8 text-left">
          <h2 className="text-2xl font-medium flex-1">Outlet Management</h2>
          <a
            onClick={onClickAdd}
            id="addOutletBtn"
            className="block capitalize py-4 px-8 rounded-full leading-none text-bold bg-redOrange text-white"
          >
            Add Outlet
          </a>

          <CustomModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            heading={"Outlets"}
            className="max-w-[70%]"
          >
            <AddOutlet form={form} onFinish={onFinish} weekDays={weekDays} />
          </CustomModal>
        </div>
        <SearchInput setSearchText={setSearchText} />
        {/* Records */}
        <div className="flex flex-col justify-between overflow-auto scrollbar outletManagementDeatils-table">
          <CustomTable
            refreshTable={refreshTable}
            columns={columns}
            endPoint={"outlet"}
            search={searchText}
            handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            handleEdit={onClickEdit}
            showActions={true}
          />
        </div>
      </div>
    </main>
  );
}

export default Outlet;
