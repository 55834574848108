import React, { useEffect, useState } from "react";
import CustomTable from "../../component/shared/customTable";
import { useNavigate } from "react-router-dom";
import { deletePurchaseById, updatePurchase } from "../../services/purchase";
import { message } from "antd";
import {
  openNotification,
  ServerError,
} from "../../component/shared/notification";
import { getRegisterDetailsApi } from "../../services/register";
import { getSelectedOutlet } from "../../redux/feature/outletSlice";
import { useSelector } from "react-redux";

function Purchase() {
  const navigate = useNavigate();
  const { selectedOutlet } = useSelector(getSelectedOutlet) || {};
  const [searchText, setSearchText] = useState("");
  const [refreshTable, setRefreshTable] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);

  const columns = [
    {
      title: "Purchase ID",
      dataIndex: "purchaseId",
      key: "purchaseId",
    },
    {
      title: "Purchase Date",
      dataIndex: "purchaseDate",
      key: "purchaseDate",
      render: (_, row) => <p>{new Date(row.purchaseDate).toDateString()}</p>,
    },
    {
      title: "Supplier",
      // dataIndex: "customer",
      // key: "customer",
      render: (_, row) => {
        return row?.customer?.fullName || row?.customerName;
      },
    },
    {
      title: "Payment Status",
      dataIndex: "paymentStatus",
      key: "paymentStatus",
    },
    {
      title: "Payment Type",
      dataIndex: "paymentType",
      key: "paymentType",
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
      render: (_, row) => {
        return Number(row?.discount).toFixed(2);
      },
    },
    {
      title: "Sub Total",
      dataIndex: "subTotal",
      key: "subTotal",
      render: (_, row) => {
        return Number(row?.subTotal).toFixed(2);
      },
    },
    {
      title: "Remaining Balance",
      dataIndex: "remainingBalance",
      key: "remainingBalance",
      render: (_, row) => {
        return Number(row?.remainingBalance).toFixed(2);
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      key: "createdBy",
      render: (_, row) => {
        return row?.createdBy?.fullName;
      },
    },
    {
      title: "Modified By",
      dataIndex: "updatedBy",
      key: "updatedBy",
      render: (_, row) => {
        return row?.updatedBy?.fullName;
      },
    },
  ];

  const getRegisterDetails = async () => {
    try {
      const { data: response } = await getRegisterDetailsApi(
        selectedOutlet._id,
      );
      if (response.data._id) {
        setOpenRegister(true);
      } else {
        setOpenRegister(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getRegisterDetails();
  }, []);

  const onClickEdit = (row) => {
    navigate("../purchase/add-purchase", { state: { id: row._id } });
  };

  const onClickDelete = async (row) => {
    try {
      const { data } = await deletePurchaseById(row._id);
      if (data.meta.success) {
        setRefreshTable((prev) => !prev);
        openNotification("success", "Record deleted successfully");
      } else {
        openNotification("error", data.error);
      }
    } catch {
      ServerError();
    }
  };

  const onClickStatus = async (row) => {
    const payload = {
      ...row,
      isActive: !row.isActive,
    };
    try {
      const response = await updatePurchase(payload, row._id);
      if (
        response.data.meta.status == 201 ||
        response.data.meta.status == 200
      ) {
        message.success(`Successfully Updated`);
        setRefreshTable((prev) => !prev);
      }
    } catch (err) {
      message.success(`Error occured`);
      console.log(err);
    }
  };

  const onClickAdd = () => {
    if (openRegister) {
      navigate("../purchase/add-purchase");
    } else {
      openNotification("error", "Register is not open");
    }
  };
  return (
    <main className="mt-6 flex-1 flex flex-col overflow-hidden">
      <div className="p-6 bg-white rounded-3xl h-full flex flex-col">
        <div className="flex items-center justify-between mb-8">
          <h2 className="text-2xl font-medium">Purchase</h2>
          <div className="flex items-center justify-end gap-6">
            {/* <Link to="/purchase/add-purchase"> */}
            <a
              // href="#"
              onClick={onClickAdd}
              className="block capitalize py-4 px-6 min-w-36 text-center rounded-full leading-none text-bold bg-redOrange text-white"
            >
              Add Purchase
            </a>
            {/* </Link> */}
          </div>
        </div>
        <form action="" className="relative mb-6">
          <input
            onChange={(e) => setSearchText(e.target.value)}
            type="text"
            placeholder="Find Purchase by name, barcode"
            className="block leading-none py-3 pr-10 pl-7 border w-full rounded-full"
          />
        </form>
        {/* Records */}
        <div className="flex flex-col justify-between overflow-auto scrollbar purchanseListing-table">
          <CustomTable
            columns={columns}
            endPoint={"purchase"}
            search={searchText}
            handleEdit={onClickEdit}
            // handleStatus={onClickStatus}
            handleDelete={onClickDelete}
            showActions={true}
            refreshTable={refreshTable}
          />
        </div>
      </div>
    </main>
  );
}

export default Purchase;
