/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tooltip, Row } from "antd";
import { Menu } from "./menu";
import { useSelector } from "react-redux";
import { getUserDetails } from "../../../redux/feature/authSlice";

function SideBar() {
  const [openIndex, setOpenIndex] = useState(null); // Tracks which dropdown is open
  const [menuWithPermission, setMenuWithPermission] = useState([]);
  const { user } = useSelector(getUserDetails) || {};

  useEffect(() => {
    const filteredSidebar = Menu.filter((item) =>
      item.permission.includes(user.userType),
    );
    setMenuWithPermission(filteredSidebar);
  }, []);

  const toggleDropdown = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // Close the dropdown if it's already open
    } else {
      setOpenIndex(index); // Open the clicked dropdown
    }
  };

  function toggleSidebar() {
    const sidebar = document.querySelector(".sidebarParentDiv");

    if (sidebar) {
      sidebar.classList.toggle("sidebarCollapse");
      const parentMenus = sidebar.querySelectorAll(".menu-item-has-children");

      parentMenus.forEach((parent) => {
        const span = parent.querySelector(".toggleArrow");
        console.log(span.length);

        if (sidebar.classList.contains("sidebarCollapse")) {
          if (span) {
            span.style.display = "none";
          }
        } else {
          if (span) {
            span.style.display = "";
          }
        }
      });
    }
  }

  return (
    <aside className="sidebarParentDiv bg-white flex flex-col sticky top-0 left-0 rounded-[1.8rem] flex-none w-52 h-full">
      <div className="sidebarHeadWrap flex items-center justify-between gap-2 py-8 px-4 cursor-pointer">
        <a className="block sidebarLogo">
          <img
            src="/img/logo.svg"
            alt="Logo"
            width=""
            height=""
            className="w-32 mx-auto"
          />
        </a>
        <div className="sidebarToggleButton" onClick={toggleSidebar}>
          <img
            src="/img/sidebarToggle.png"
            alt="Icon"
            className="w-6 mx-auto h-auto"
          />
        </div>
      </div>
      <ul className="scrollbar overflow-y-auto sidebarItemList">
        {menuWithPermission.map((menus, index) => (
          <Row>
            <Tooltip
              overlayClassName="sidebarItemListTooltip"
              placement="right"
              title={
                <>
                  <p className="text-sm font-medium">{menus.label}</p>
                </>
              }
            >
              <li
                key={index}
                className={`group mb-3 last:mb-0 ${menus?.children?.length ? "menu-item-has-children" : ""} ${
                  openIndex === index ? "active" : ""
                }`}
              >
                <Link to={menus.to}>
                  <div
                    onClick={() => toggleDropdown(index)}
                    className="relative py-1 px-5 flex items-center justify-start cursor-pointer transition ease-in-out group-hover:bg-redOrange group-[.menu-item-has-children.active]:bg-redOrange sidebarItemListing"
                  >
                    {menus.icon}
                    <span className="sidebarItemListingContent text-sm text-lightGrey transition ease-in-out group-hover:text-white group-[.menu-item-has-children.active]:text-white ml-2">
                      {menus.label}
                    </span>

                    {menus?.children?.length && (
                      <span class="toggleArrow absolute top-5 right-4 transition ease-in-out group-[.menu-item-has-children.active]:rotate-180">
                        <svg
                          width="12"
                          height="7"
                          viewBox="0 0 12 7"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            class="group-hover:stroke-white group-[.menu-item-has-children.active]:stroke-white"
                            d="M1 1L6 6L11 1"
                            stroke="black"
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </span>
                    )}
                  </div>
                </Link>
                {menus.children && openIndex === index && (
                  <ul
                    className={`sidebarItemListingSubmenu bg-veryLightGrey py-2 transition-all ease-in-out duration-300 ${
                      openIndex === index ? "block active" : "hidden"
                    }`}
                  >
                    {menus.children.map((child, childIndex) => (
                      <li key={childIndex}>
                        <Link
                          to={child.to}
                          className="text-left block text-sm text-black py-2 pr-4 pl-10"
                        >
                          {child.label}
                        </Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            </Tooltip>
          </Row>
        ))}
      </ul>
    </aside>
  );
}

export default SideBar;
