import { Button, Form, Input, Radio, Select } from "antd";
import React from "react";
import CustomSelect from "../shared/customSelect/indes";

function AddProductToProcrss({
  form,
  onFinishListingModal,
  selecectedAttribute,
  tempAttibute,
}) {
  return (
    <div className="variant-product-popup">
      <Form form={form} onFinish={onFinishListingModal}>
        <div className="flex items-center gap-3">
          <label
            htmlFor=""
            className="text-black text-sm flex justify-start mb-2"
          >
            Quantity:
          </label>
          <div className="flex justify-start gap-2 mt-3">
            <Form.Item
              name="quantity"
              rules={[{ required: true, message: "This is required" }]}
            >
              <input
                type="number"
                value={1}
                defaultValue={1}
                className="block text-sm w-24 border rounded-full py-2 px-3 text-md leading-none text-center outline-0"
              />
            </Form.Item>
          </div>
        </div>

        {/* <label
          htmlFor=""
          className="text-black text-sm flex justify-start mb-2"
        >
          Product Name
        </label>*/}
        <Form.Item
          style={{ display: "none" }}
          name="name"
          rules={[{ required: true, message: "This is required" }]}
        >
          <Input
            disabled
            className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
          />
        </Form.Item>
        <Form.Item style={{ display: "none" }} name="_id">
          <Input
            disabled
            className="block w-full border focus:border-ufoGreen rounded-full py-3 px-6"
          />
        </Form.Item>
        {tempAttibute?.map((attribute) => (
          <Form.Item
            key={attribute._id}
            name={attribute.type} // Use type as the unique name for each form item
            label={attribute.type} // Set the label to type
            className="capitalize"
            rules={[
              { required: true, message: `Please select a ${attribute.type}!` },
            ]}
          >
            <Radio.Group>
              {attribute.values.map((value) => (
                <Radio.Button key={value} value={value}>
                  {value}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        ))}

        {/* <Form.Item
          name="radio-button"
          label="Color"
          rules={[{ required: true, message: 'Please pick an item!' }]}
        >
          <Radio.Group>
            <Radio.Button value="a">item 1</Radio.Button>
            <Radio.Button value="b">item 2</Radio.Button>
            <Radio.Button value="c">item 3</Radio.Button>
          </Radio.Group>
        </Form.Item>


        <Form.Item
          name="radio-button"
          label="Size"
          rules={[{ required: true, message: 'Please pick an item!' }]}
        >
          <Radio.Group>
            <Radio.Button value="a">item 1</Radio.Button>
            <Radio.Button value="b">item 2</Radio.Button>
            <Radio.Button value="c">item 3</Radio.Button>
          </Radio.Group>
        </Form.Item> */}

        <div style={{ display: "none" }} className="mt-8">
          <label
            htmlFor=""
            className="text-black text-sm flex justify-start mb-2"
          >
            Attribute
          </label>
          <Form.Item
            name="attribute"
            rules={[{ required: true, message: "This is required" }]}
          >
            <CustomSelect
              disabled
              mode="multiple"
              options={selecectedAttribute}
            />
          </Form.Item>
          {/* <div className="flex justify-start gap-2 mt-3">
            <div className="bg-[#5B4CFF] border border-[#5B4CFF] w-12 aspect-square rounded-full cursor-pointer transition ease-in-out hover:shadow-[0_0.3rem_0.6rem_0_rgba(0,0,0,0.3)] hover:border-white" />
            <div className="bg-[#FF4C4C] border border-[#FF4C4C] w-12 aspect-square rounded-full cursor-pointer transition ease-in-out hover:shadow-[0_0.3rem_0.6rem_0_rgba(0,0,0,0.3)] hover:border-white" />
            <div className="bg-[#000000] border border-[#000000] w-12 aspect-square rounded-full cursor-pointer transition ease-in-out hover:shadow-[0_0.3rem_0.6rem_0_rgba(0,0,0,0.3)] hover:border-white" />
            <div className="bg-[#00A843] border border-[#00A843] w-12 aspect-square rounded-full cursor-pointer transition ease-in-out hover:shadow-[0_0.3rem_0.6rem_0_rgba(0,0,0,0.3)] hover:border-white" />
          </div> */}
        </div>
        {/* <div className="mt-8">
          <label htmlFor="" className="text-black text-sm">
            Size
          </label>
          <div className="flex justify-start gap-2 mt-3">
            <div className="flex items-center justify-center text-sm text-lightGrey border border-lightGrey w-12 aspect-square rounded-full cursor-pointer transition ease-in-out hover:shadow-[0_0.3rem_0.6rem_0_rgba(0,0,0,0.3)] hover:border-white hover:bg-ufoGreen hover:text-white">
              S
            </div>
            <div className="flex items-center justify-center text-sm text-lightGrey border border-lightGrey w-12 aspect-square rounded-full cursor-pointer transition ease-in-out hover:shadow-[0_0.3rem_0.6rem_0_rgba(0,0,0,0.3)] hover:border-white hover:bg-ufoGreen hover:text-white">
              M
            </div>
            <div className="flex items-center justify-center text-sm text-lightGrey border border-lightGrey w-12 aspect-square rounded-full cursor-pointer transition ease-in-out hover:shadow-[0_0.3rem_0.6rem_0_rgba(0,0,0,0.3)] hover:border-white hover:bg-ufoGreen hover:text-white">
              L
            </div>
          </div>
        </div> */}
        <div className="flex items-center justify-center gap-3 mt-7">
          <Button
            type="primary"
            htmlType="submit"
            className="h-auto cursor-pointer leading-none block py-4 px-12 w-full bg-redOrange rounded-full text-white font-medium"
          >
            Confirm
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default AddProductToProcrss;
