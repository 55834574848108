import Api from "../../network/client";

export const getAllProductInventorry = async (id) => {
  return Api.get(`outlet/${id}/opening-balance`);
};

export const getAllProductAddAndSet = async (query) => {
  return Api.get(`inventory-ledger?${query}`);
};

export const updateAllProductInventorry = async (data, id) => {
  return Api.put(`outlet/${id}/opening-balance`, data);
};

export const updateInventorryLedger = async (data) => {
  return Api.post(`inventory-ledger`, data);
};
