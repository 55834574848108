import { Input, Form } from "antd";
import React from "react";
import CustomSelect from "../../shared/customSelect/indes";
import { dropDownPayload } from "../../../utils/helper";

function Category({ productTypes, brands, tags, season }) {
  return (
    <div className="mt-6 grid grid-cols-2 xl:!grid-cols-1 gap-4 xl:!gap-0 categoriesMultipleSelectList">
      <Form.Item
        name="productTypeIds"
        label={
          <span className="text-black text-md ml-2 font-medium">Category</span>
        }
      >
        <CustomSelect
          className="rounded-md"
          mode="multiple"
          options={dropDownPayload(productTypes)}
        />
      </Form.Item>
      <Form.Item
        name="brandId"
        label={
          <span className="text-black text-md ml-2 font-medium">Brand</span>
        }
      >
        <CustomSelect options={dropDownPayload(brands)} />
      </Form.Item>

      <Form.Item
        name="tagIds"
        label={
          <span className="text-black text-md ml-2 font-medium">Tags</span>
        }
      >
        <CustomSelect mode="multiple" options={dropDownPayload(tags)} />
      </Form.Item>

      <Form.Item
        name="seasonIds"
        label={
          <span className="text-black text-md ml-2 font-medium">Season</span>
        }
      >
        <CustomSelect mode="multiple" options={dropDownPayload(season)} />
      </Form.Item>

      <Form.Item
        name="loyalityPoints"
        label={
          <span className="text-black text-md ml-2 font-medium">
            Additional Loyalty Points
          </span>
        }
      >
        <Input
          type="number"
          placeholder="Loyality Points"
          className="block leading-none py-3 px-6 border border-lightGrey w-full rounded-full outline-0"
        />
      </Form.Item>
    </div>
  );
}

export default Category;
