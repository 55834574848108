import React from "react";
import { Button, Popconfirm } from "antd";

const CustomPopConfirm = ({
  title,
  description,
  onConfirm,
  onCancel,
  okText,
  cancelText,
  placement,
  icon,
  children,
  disabled,
}) => (
  <Popconfirm
    title={title}
    description={description}
    onConfirm={onConfirm}
    onCancel={onCancel}
    okText={okText}
    cancelText={cancelText}
    placement={placement}
    icon={icon}
    disabled={disabled}
    okButtonProps={{ className: "bg-redOrange" }}
  >
    <Button className={"pop-confirm-btn px-1"}>{children}</Button>
  </Popconfirm>
);

export default CustomPopConfirm;
