import Api from "../../network/client";

export const updatePurchase = async (data, id) => {
  return Api.put(`purchase/${id}`, data);
};

export const deletePurchaseById = async (id) => {
  return Api.delete(`purchase/${id}`);
};

export const createPurchase = async (data) => {
  return Api.post(`purchase`, data);
};
export const getPurchaseById = async (id) => {
  return Api.get(`purchase/${id}`);
};
