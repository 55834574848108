import React from "react";
import TabsComponent from "../../component/shared/tabs";
import { tabData } from "./tabdata";

function Settings() {
  return (
    <main className="mt-4 flex-1 flex flex-col overflow-hidden">
      <h2 className="text-2xl font-medium mb-4 flex justify-start">
        Landing Page Settings
      </h2>

      <TabsComponent tabData={tabData} />
    </main>
  );
}

export default Settings;
